
.button {
    width: 400px;
    height: 50px;

    background-color: #009FFF;

    color: #fff;

    font-family: "Source Sans Pro";

    font-size: 17px;

    line-height: 21px;
    
    font-weight: 600;

    letter-spacing: normal;

    transition: 0.1s;

    &:hover,
    &:focus {
        background-color: #008DFF;
    }

    &.disabled,
    &:disabled,
    &[disabled] {
        background-color: #F5F5F5;

        color: rgba(162,162,162,0.5);
    }
}