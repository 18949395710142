@import "src/colorVariables.scss";

.with-tooltip,
.with-tooltip-2 {
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.with-tooltip-2 {
  justify-content: flex-start;

  gap: 10px;
}

.tooltip-card {
  padding: 15px;
  max-width: 450px;

  width: 100%;

  font-size: 16px;

  text-align: justify;
}

.new-hiking {
  height: 100%;
  .gray {
    color: gray;
  }
  .close-hiking {
    position: absolute;
    right: 20px;
    top: 6px;
    font-size: 24px;
    cursor: pointer;
  }
  .w-content {
    height: calc(100% - 130px);
    overflow: auto;

    .uploaded-images {
      width: 100%;
      .one-image {
        position: relative;
        margin-top: 0;
        margin-bottom: 40px;
        box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.2s;
        }
        .drag-element {
          position: absolute;
          height: 200px;
          width: 100%;
          left: 0;
          top: 0;
          cursor: move;
        }
        .remove-image {
          position: absolute;
          top: 3px;
          right: 25px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .move-image {
          position: absolute;
          top: 3px;
          right: 45px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .feature-wrap {
          font-size: 12px;
          margin-top: 20px;
          display: flex;
          align-items: center;

          .checkbox {
            height: 14px;
            margin-right: 5px;
          }
          label {
            margin: 0;
          }
        }
        .text-wrap {
          margin-top: 15px;
          .flag {
            object-fit: contain;
            display: inline-block;
            width: 30px;
            height: unset;
            opacity: 1;
            margin: 5px 0 2px;
            pointer-events: none;
          }
          .vfi-text-area {
            textarea {
              height: 100px;
            }
          }
        }
      }
      .one-image:hover {
        img {
          opacity: 0.6;
        }
        .remove-image {
          opacity: 1;
        }
        .move-image {
          opacity: 1;
        }
        .text-wrap {
          opacity: 1;
        }
      }
    }
    .one-image.dragging-on,
    .insta.dragging-on {
      background-color: $light;
      opacity: 0.5;
    }
    .one-image.dragging,
    .insta.dragging {
      opacity: 0;
      overflow: hidden;
    }

    .new-content {
      padding: 80px 60px 20px;
      h1 {
        font-size: 30px;
        font-weight: 600;
      }
      .fill-form {
        display: flex;
        margin-top: 50px;
        .language-box {
          display: inline-block;
          img.flag {
            width: 30px;
          }
          .l-include {
            margin-left: 20px;
          }
          .headline,
          .slug-url-wrap,
          .small-description,
          .description,
          .summary {
            margin-top: 30px;
            div.text {
              display: inline-block;
              vertical-align: top;
              margin-top: 16px;
            }
            .vfi-input-text,
            .vfi-text-area {
              display: inline-block;
            }
          }
          .headline {
            margin-top: 30px;
          }
          .slug-url-wrap {
            margin-top: 30px;
            p {
              display: inline-block;
            }
            .vfi-input-text {
              display: inline-block;
            }
          }
          .small-description {
            .ck-editor {
              .ck-content {
                height: 120px;
              }
            }
          }
          .description {
            .ck-content {
              height: 240px;
              width: 100%;
              background-color: rgba($color: $light, $alpha: 0.5);
            }
          }
          .expand-wrap {
            text-align: right;
            color: $blue;

            font-size: 14px;
            position: relative;
            span {
              cursor: pointer;
              position: absolute;
              right: 0;
            }
            span:hover {
              color: $light-blue;
            }
          }
        }
        .english {
          padding-right: 3vw;
        }
        .faroese {
          padding-left: 3vw;
        }
      }
      .start-time {
        margin-top: 15px;
      }
    }

    .new-content.two {
      .place-categories {
        margin-top: 60px;
        display: flex;
        height: 360px;
        flex-flow: wrap column;
        align-items: flex-end;
        width: 60%;
        .group {
          width: 30%;
        }
      }

      .image-upload-block {
        cursor: pointer;
        margin: unset;
        .upload-box {
          height: 100%;
        }
        .image-container {
          margin: auto;
          height: 100%;
          position: relative;
          max-height: 400px;
          .before-image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 64px;
            color: black;
          }
          .pen {
            position: absolute;
            right: 15px;
            top: 15px;

            padding: unset;
          }
          img {
            width: 298px;
            height: 190px;
            object-fit: contain;
          }
        }
      }
    }

    .new-content.three {
      p {
        margin: 0;
      }
      .first-practical {
        .difficulty-wrap,
        .distance-wrap,
        .elevation-wrap,
        .max-height-wrap,
        .extra-filters-wrap,
        .duration-wrap {
          margin-top: 20px;

          .flex-distribution {
            display: flex;
            flex-grow: auto;
            .vfi-input-text {
              flex-grow: 1;
            }
            .what-value {
              display: flex;
              p {
                margin-left: 10px;
              }
            }
            .hours,
            .minutes {
              flex-grow: 1;
              margin-right: 10px;
            }
          }

          p {
            margin: auto;
          }

          .extra-filter {
            margin-right: 20px;
            white-space: nowrap;
            label {
              margin: 0;
              height: 20px;
              vertical-align: middle;
            }
            .filter-text {
              display: inline-block;
              vertical-align: middle;
            }
          }
          .many-lines {
            flex-wrap: wrap;
          }
        }
      }
      .flag {
        margin-top: 30px;
        width: 30px;
      }
      .english,
      .faroese {
        .difficulty-wrap,
        .children-d-wrap,
        .route-surface-wrap,
        .bus-route-wrap {
          margin-bottom: 30px;
        }
      }
    }

    .address {
      position: relative;
      margin: 0;
      label {
        width: 100%;
        padding: 0;
      }
      .toogle-street {
        text-align: right;
        position: relative;
        p {
          margin: 0 0;
          display: inline-block;
          vertical-align: middle;
          padding-right: 5px;
        }
        .the-switch {
          vertical-align: middle;
        }
      }
      .map-wrap {
        width: 100%;
        margin: 0;
        margin-top: -32px;
        margin-bottom: 30px;
        p {
          margin-bottom: 5px;
        }
        .pin-map,
        .street-map {
          display: inline-block;
          width: 50%;
        }

        .pin-map {
          width: 100%;
        }

        .street-map {
          padding-left: 2vw;
          padding-right: 0;
        }
      }
      .region {
        padding: 0;
        p {
          margin: 0;
        }
      }
    }

    .new-content.five {
      .address {
        position: relative;
        margin: 0;
        label {
          width: 100%;
          padding: 0;
        }

        p {
          margin-bottom: 5px;
        }
        .pin-map,
        .street-map {
          display: inline-block;
          width: 50%;
        }

        .pin-map {
          width: 100%;
        }

        .street-map {
          padding-left: 2vw;
          padding-right: 0;
        }
        .region {
          padding: 0;
          p {
            margin: 20px 0 0 0;
          }
        }
      }

      .edit-contents {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .row {
          width: 100%;
          .upload-box {
            height: 100%;
            .image-container {
              margin: auto;
              border: 1px solid rgba(0, 0, 0, 0.06);
              width: 100%;
              position: relative;
              .before-image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 64px;
                color: $blue;
              }
              .gpx-label {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -30%);
                font-size: 12px;
                font-weight: 600;
                color: $blue;
              }
              .pen {
                position: absolute;
                right: 15px;
                top: 15px;

                opacity: 0.5;
                padding: unset;
              }
              img {
                width: 298px;
                height: 190px;
                object-fit: contain;
              }
            }
          }
        }

        ul {
          padding-left: 0;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-evenly;
          justify-content: flex-start;
        }
        li {
          list-style-type: none;
          display: inline-block;
          margin-right: 10px;
          width: 350px;
        }

        .gpx-content {
          padding: 20px 0 0;

          display: flex;
          align-items: baseline;
          justify-content: space-around;
          .remove-button {
            cursor: pointer;
          }
        }
      }
      .gpx-topper {
        margin: 0 0 20px;
      }

      .gpx-wrap {
        padding-left: 0;
        padding-right: 0;
        .image-upload-block {
          aspect-ratio: unset;
          height: 200px;
        }
      }
      .relive-wrap {
        margin-bottom: 60px;
        padding-left: 0;
        padding-right: 0;
        .one-media {
          .image-container {
            height: 100%;
            max-height: unset;
          }
        }
      }
    }

    .new-content.five,
    .new-content.six,
    .new-content.seven {
      .vfi-custom-select-wrap {
        padding-right: 0;
      }
      .street-top {
        margin-top: 20px;
        .toogle-street {
          text-align: right;
          position: relative;
          p {
            margin: 0 0;
            display: inline-block;
            vertical-align: middle;
            padding-right: 5px;
          }
          .the-switch {
            vertical-align: middle;
          }
        }
      }
      .uploaded-images-p {
        margin-top: 20px;
      }
      img.flag {
        width: 30px;
        vertical-align: middle;
      }
      .getting-there-fo,
      .getting-there-en,
      .starting-point-fo,
      .starting-point-en,
      .map-description-fo,
      .map-description-en,
      .images-description-top-text {
        margin-top: 20px;
        div.text {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }
        .vfi-input-text,
        .ck-editor {
          margin-top: 10px;
          .ck-content {
            height: 200px;
          }
        }
        .ck-content {
          background-color: rgba(162, 162, 162, 0.05);
        }
        p {
          margin-top: 20px;
        }
      }

      .getting-there-en,
      .starting-point-en,
      .map-description-en {
        padding-right: 15px;
      }

      .getting-there-fo,
      .starting-point-fo,
      .map-description-fo {
        padding-left: 15px;
      }

      .image-upload-block {
        margin: unset;
      }
      .row {
        height: 100%;
      }
      .upload-box {
        height: 100%;
        .image-container {
          height: 100%;
          max-height: 200px;
          margin: auto;

          position: relative;
          .before-image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 64px;
            color: black;
          }
          .pen {
            position: absolute;
            right: 15px;
            top: 15px;

            opacity: 1;
            padding: unset;
          }
          img {
            width: 298px;
            height: 190px;
            object-fit: contain;
          }
        }
      }
    }

    .new-content.six {
      .getting-there-en,
      .getting-there-fo {
        .vfi-text-area {
          .ck-editor {
            .ck-editor__top {
              display: block;
              .ck-toolbar {
                border-width: 0;
                background: transparent;
              }
            }
          }
        }
      }
    }

    .new-content.eight {
      .instagram-enabled {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        margin: 20px 0;
        width: fit-content;
        label {
          margin-bottom: 0;
          height: 20px;
        }
        > label {
          margin-right: 10px;
        }
      }
      .edit-contents {
        opacity: 1;
        transition: opacity 0.5s;
      }

      .edit-contents.hidden {
        user-select: none;
        opacity: 0;
        pointer-events: none;
      }
      .row {
        margin: 0;
      }
    }

    .new-content.nine {
      .new-edit {
        margin-top: 60px;
        .final-text {
          width: 100%;
          margin: auto;
          text-align: center;
          .wooh {
            color: $blue;
            font-size: 75px;
            width: 20%;
            display: inline-block;
          }
          .wrap-final-text {
            text-align: left;
            display: inline-block;
            p {
              font-size: 22px;
              font-weight: 600;
              color: $mid;
              margin: 0;
            }
          }
          margin-bottom: 40px;
        }
        .post-button {
          background-color: $blue;
          width: min-content;
          margin: auto;
          padding: 10px 50px;
          color: white;
          font-weight: 600;
          font-size: 28px;
          cursor: pointer;
          user-select: none;
          transition: ease-in-out 0.2s;
        }
        .post-button:hover {
          background-color: $light-blue;
        }
      }
    }
  }
}
