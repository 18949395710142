@import "src/colorVariables.scss";
.extra-video-link-text {
  margin-top: 30px;
  padding: 10px 0 0 0;
  margin: 0 0 5px 0;
  // color: rgba(100, 100, 100, 0.3);
  color: $mid;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}

.video-links {
  max-width: 670px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  .video-link {
    display: flex;
    // grid-template-columns: 90% 10%;
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    .url-input {
      width: 100%;
    }
    .trash-icon {
      text-align: center;
      // position: relative;
      background-color: transparent;
      position: absolute;
      top: 50%;
      right: -40px;
      transform: translate(-50%, -50%);
      font-size: 16px;
      cursor: pointer;
    }
  }
  .add-extra-video-link {
    // width: 100%;
    background-color: transparent;
    text-align: center;

    @media (max-width: 1200px) {
      width: 100%;
    }
    .add-extra-video-link-wrap {
      text-align: center;
      cursor: pointer;
      user-select: none;
      width: 100px;
      margin: auto;
      span.text {
        margin-right: 8px;
      }
      &:hover {
        span.text {
          text-decoration: underline;
        }
      }
    }
  }
}
