@use "../../../../../../../breakpointVariables" as breakpoints;

.container {

    padding: 20px 0 0;
    
    @media (breakpoints.$sendistovanTablet) {
        padding: 2px 20px;
    }

    width: 100%;

    overflow-x: visible;
    overflow-y: hidden;

    transition: 0.1s;

    .content {

        padding: 0 2px;

        @media (breakpoints.$sendistovanTablet) {
            padding: 0;
        }

        width: 100%;
        height: fit-content;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        gap: 16px;

        .map_input_wrapper {
            width: 100%;

            &.error {
                outline: 2px solid red;
            }
        }

        .map_wrapper {
            width: 100%;
        }

        .location_details {

            padding-bottom: 256px;

            width: 100%;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            gap: 24px;

            @media (breakpoints.$sendistovanMobile) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                gap: 8px;
            }

            .detail_title {
                width: 100%;

                text-align: left;
            }

            .region,
            .zip {

                min-width: 220px;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                gap: 4px;

                @media (breakpoints.$sendistovanMobile) {
                    min-width: 100%;
                }
            }

        }
    }
}