.checkboxInput {
  display: none;
}

.checkboxLabel {
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  position: relative;

  gap: 12px;

  margin: 0;
  padding: 0;
}

.checkboxLabelText {
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: left;
  user-select: none;
}

.checkbox {
  display: inline-block;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: inset 0px 0px 0px 2px #ebedf2;
  position: relative;
  transition: ease-in-out 0.2s;

  border-radius: 4px;
}

.checkbox:hover {
  box-shadow: inset 0px 0px 0px 2px #cbcdd2;
}

.checkboxInput:checked + .checkboxLabel .checkbox {
  background-color: #000000;
  box-shadow: none;
}

.checkboxInput:checked + .checkboxLabel .checkbox::before,
.checkboxInput:checked + .checkboxLabel .checkbox::after {
  content: "";
  position: absolute;
  background-color: white;
}

.checkboxInput:checked + .checkboxLabel .checkbox::before {
  top: 10px;
  left: 4px;
  width: 6px;
  height: 2.5px;
  transform: rotate(45deg);
}

.checkboxInput:checked + .checkboxLabel .checkbox::after {
  top: 8px;
  left: 7px;
  width: 10px;
  height: 2.5px;
  transform: rotate(-45deg);
}
