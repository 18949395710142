@use "../../../../../../breakpointVariables.scss" as breakpoints;

$media-height: 210px;

.container {
  .media_elements {
    column-gap: 20px;

    display: flex;
    flex-wrap: wrap;

      @media (breakpoints.$sendistovanTablet) {
        justify-content: center;
      }

    .media {
      width: fit-content;
      max-width: 420px;

      display: flex;
      flex-basis: 291px;
      // flex-basis: 325px;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      gap: 10px;

      position: relative;
      margin-bottom: 20px;

      &.no-margin-bottom {
        margin-bottom: 0;
      }
      &.drag_over {
        background-color: rgba(100, 100, 100, 0.05);
      }
      .image_wrapper {
        width: 100%;
        height: $media-height;
      }
      .image {
        width: 100%;
        height: 100%;

        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        object-fit: cover;

        cursor: grab;

        transition: 0.1s;

        .upload_content {
          transition: 0.1s;
        }

        &:hover {
          .upload_content {
            background-color: rgba(100, 150, 200, 0.1);
          }
        }

        &.dragging {
          opacity: 0.4;
        }

        &.to_drop {
          opacity: 0.8;
        }
      }

      .move_overlay {
        width: 100%;
        height: $media-height;

        background-color: rgba(0, 0, 0, 0.05);

        opacity: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 0;
        left: 0;

        pointer-events: none;

        transition: 0.1s;

        .move_icon {
          color: #fff;
        }
      }

      &.empty {
        height: $media-height;
        .image {
          background-color: #373737;

          cursor: pointer;

          opacity: 1;

          transition: 0.1s;

          .upload_content {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            gap: 20px;

            pointer-events: none;

            .icon {
              color: #fff;

              font-size: 20px;
            }

            p {
              padding: 0;
              margin: 0;

              color: #fff;

              font-size: 14px;

              text-transform: uppercase;
            }
          }

          &.hovered {
            opacity: 0.8;
          }
        }
      }

      &:hover {
        .move_overlay {
          opacity: 1;
        }
      }

      .input {
        width: 100%;

        transition: opacity 0.1s;

        &.disabled {
          user-select: none;
          opacity: 0.2;
        }
      }

      .details {
        pointer-events: none;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        gap: 10px;

        position: absolute;
        top: 8px;
        left: 8px;

        user-select: none;

        .detail {
          padding: 3px 8px;

          height: 22px;

          border-radius: 2px;

          font-size: 10px;

          background-color: #fff;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .options {
        // padding: 0 10px;

        width: 22px;
        height: 22px;

        border-radius: 2px;

        font-size: 16px;

        background-color: #fff;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 8px;
        right: 8px;

        transition: 0.1s;

        &:hover {
          background-color: #f3f3f3;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }

        .ellipsis {
          pointer-events: none;
        }
      }
    }
  }

  .options_overlay {
    position: fixed;

    border-radius: 2px;

    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .option {
      padding: 10px;
      padding-right: 15px;

      width: 100%;

      background-color: #fff;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      gap: 10px;

      transition: 0.1s;

      .option_icon {
        width: 20px;
      }

      p {
        margin: 0;
        padding: 0;
      }

      &:hover {
        background-color: #f3f3f3;

        opacity: 0.9;
      }
    }

    .set_main {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .delete {
      color: #f64e60;

      p {
        color: #f64e60;
      }
    }
  }
}
