.datePickerClearButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  background-color: #f5f5f5;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 20px;
  height: 20px;

  color: var(--body, #373737);
  font-size: 13px;

  &:hover {
    background-color: #e5e5e5;
  }

  &:focus {
    outline: 2px solid #009fff;
  }

  &:active {
    background-color: #d5d5d5;
  }
}
