.content {
  background-color: rgba($color: #ffffff, $alpha: 1);
  display: inline-block;
  position: relative;
  // height: 100%;
  // vertical-align: top;
  flex-grow: 1;
  // margin: auto;
  // display:flex;
  // width: auto;
  width: calc(100% - 200px);
  //box-shadow: -15px 0 15px rgba($color: #EAEAEA, $alpha: 1.0);
  height: 100%;
  overflow: auto;

  .shadow-maker {
    position: absolute;
    width: 50px;
    height: 99%;
    background-color: grey;
    left: 18px;
    top: 0.5%;
    transform: rotate(0.5deg);
    box-shadow: -15px 0px 20px lightgrey;
    z-index: -1;
  }
}

.content.noScroll {
  overflow-y: hidden;
}
