@import "src/colorVariables.scss";
.time-select{
    margin-right: 2px;
    margin-bottom: 2px;
    .time-select__control{
        border: none;
        background-color: $input-fill;
        border-radius: 0;
        padding: 7px 9px;
        .time-select__indicators{
            display: none;
        }
    }
}