@use "../../../../breakpointVariables" as breakpoints;

.container {
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  @media screen and (breakpoints.$sendistovanTablet) {
    margin-bottom: unset;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 44px 0 0 0;

  @media screen and (breakpoints.$sendistovanTablet) {
    padding: 40px 20px;
  }

  .title,
  .subtitle {
    text-align: left;
    font-family: "Source Sans Pro", sans-serif;

    margin: 0;
  }

  .title {
    font-size: 30px;
    line-height: 33px;
    font-weight: 700;

    @media screen and (breakpoints.$sendistovanTablet) {
      font-size: 22px;
      line-height: 22px;
      letter-spacing: 0.5px;
    }
  }

  .subtitle {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;

    @media screen and (breakpoints.$sendistovanTablet) {
      font-size: 17px;

      // Design says 27px, but it's too big
      line-height: 27px;
    }
  }
}
