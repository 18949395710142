.datePickerClearButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
}

.label {
  color: var(--color-basic-black, #000);
  font-family: "Source Sans Pro";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.icon {
  font-size: 14px;
  color: #373737;
}
