@import "src/colorVariables.scss";
.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    gap: 23px;

    position: relative;

    .logo {
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
    }

    .text {
      width: 400px;

      padding: 10px 20px;

      p {
        padding: 0;
        margin: 0;

        color: #313131;

        font-size: 14px;
        line-height: 24px;
        font-family: "Source Sans Pro";
        text-align: left;
      }
    }

    input::placeholder {
      color: rgba(0, 0, 0, 0.2) !important;
      font-family: "Source Sans Pro";
      font-weight: 600;
    }

    .input {
      width: 100%;
    }

    .spinner {
      // color: #fff;
      color: rgba(162, 162, 162, 0.5);
    }

    .bottom_wrapper {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .go_back {
        padding: 0 10px;

        background-color: transparent;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: 5px;

        .icon,
        p {
          padding: 0;
          margin: 0;

          color: #a2a2a2;

          font-family: "Source Sans Pro";

          line-height: 22px;

          font-size: 15px;
        }
      }
    }

    .no_mail {
      padding: 0 0 20px 0;
      margin: 0;

      width: 100%;

      text-align: center;

      background-color: transparent;

      font-size: 12px;

      font-weight: bold;

      color: #a2a2a2;

      &:hover {
        text-decoration: underline;
      }
    }

    .link_sent {
      padding: 0 10px;
      margin: 0;
    }
  }

  .password-title {
    color: #373737;
    font-family: "Libre Baskerville";
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    padding-top: 20px;
  }

  .info {
    max-width: 410px;
    margin: auto;
    margin-top: 30px;
    text-align: left;
    margin-bottom: 42px;
    &.center {
      text-align: center;
    }
    .blue {
      color: $blue;
      font-weight: bold;
    }
  }
}

.error_popout {
  box-sizing: border-box;

  padding: 0 40px 0 30px;

  height: 100%;

  background-color: rgba(255, 100, 100, 0.5);

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
