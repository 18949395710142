.selectBox {
  width: 54px;
  height: 50px;
}

.selectBoxMenu {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b8b8b8;

    transition: 0.2s;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #838383;
  }
}

.error {
  outline: 2px solid red;
}