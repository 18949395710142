@use "../../../../../breakpointVariables" as breakpoints;

.toggle {

    padding: 40px 0 40px;
    
    @media (breakpoints.$sendistovanTablet) {
        padding: 0 20px 40px;
    }

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 8px;

    .error {       
        color: red;
        font-size: 12px;
        font-weight: 400;    

        text-align: left;
        
    }
}

.form {

    padding: 0 0 120px;
    
    @media (breakpoints.$sendistovanTablet) {
        padding: 0 20px 120px;
    }

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 16px;

    transition: opacity 0.1s;

    &.disabled {
        opacity: 0;

        pointer-events: none;

        user-select: none;
    }

    .input {

        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        gap: 4px;

        .label {

            text-align: left;

            // text-transform: capitalize;
        }

        .input_field {

            padding: 0;

            width: 380px;

            @media (breakpoints.$sendistovanMobile) {
                width: 100%;
            }

        }

        .phone_wrapper {

            $phone_gap: 8px;
                
            display: flex;
            flex-direction: row;

            gap: $phone_gap;

            .country_code {
                max-width: 120px;
                min-width: 100px;

                height: 100%;
            }

            .phone_number {

                padding: 0;

            }
        }
    }

    .input_organizer {

    }

}
