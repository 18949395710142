@use "../../../../../breakpointVariables" as breakpoints;

.content {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 50px;

  @media screen and (breakpoints.$sendistovanTablet) {
    justify-content: space-between;
    max-width: 100%;

    margin-top: unset;
    padding: 0px 20px 20px;
  }
}

.mediaContainer {
  width: 100%;
}


.optionalContent {


  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;

  .title {

    font-size: 15px;

    font-weight: 700;

    padding-bottom: 16px;

  }

  .examples {

    font-size: 12px;

    font-weight: 600;

    color: #ACACAC;

  }

  .text {
    padding-bottom: 8px;

    font-size: 16px;

    text-align: left;
  }

  .optionalContentInput {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 8px;

    .removeButton {

      padding: 0;
      margin: 0;

      background-color: rgba(0, 0, 0, 0);
    }
  }

  .addButton {


    display: flex;
    flex-direction: row;
    justify-content: flex-start;  
    align-items: center;

    gap: 4px;

    background-color: rgba(0, 0, 0, 0);

    .text {
      padding: 0;
      margin: 0;

      text-decoration: underline;
    }
  }

}

.seperator {
  width: 100%;
  height: 1px;
  background-color: #acacac3f;
  margin-top: 32px;
}