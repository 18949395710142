@import "src/colorVariables.scss";
@font-face {
  font-family: "Libre Baskerville Bold";
  src: local("Libre Baskerville Bold"), url(./fonts/LibreBaskerville-Bold.ttf) format("tff");
}
@font-face {
  font-family: "Libre Baskerville Italic";
  src: local("Libre Baskerville Italic"), url(./fonts/LibreBaskerville-Italic.ttf) format("tff");
}
@font-face {
  font-family: "Libre Baskerville Regular";
  src: local("Libre Baskerville Regular"), url(./fonts/LibreBaskerville-Regular.ttf) format("tff");
}

.font-face-gm {
  font-family: "VFIfonts", Fallback, sans-serif;
}
.App {
  text-align: center;
  background-color: $color-background-gray;
  height: 100%;
  overflow: hidden;
  .login-overlay {
    position: fixed;
    z-index: 30;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: black, $alpha: 0.8);

    .login-section {
      display: flex;
      flex-direction: column;
      .logged-out-text {
        color: white;
        font-size: 24px;
        text-align: center;
      }
      .imgcontainer {
        background-color: white;
        margin: 0 !important;
        padding: 20px;
      }
      #Login {
        position: relative;
        background-color: white;
        padding-bottom: 0;
        flex-direction: column;
        border-radius: 2px;
        .container {
          padding-bottom: 30px !important;
          label {
            display: flex;
            align-items: center;
            input[type="checkbox"] {
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  position: relative;

  &:disabled {
    cursor: auto;
  }
}
