@use "../../../../breakpointVariables" as breakpoints;

.container {
  display: flex;
  gap: 15px;

  @media screen and (breakpoints.$sendistovanTablet) {
    gap: 10px;
  }
}

.horizontalContainer {
  flex-direction: row;
  align-items: center;
}

.verticalContainer {
  flex-direction: column;
  align-items: start;
}

.label {
  font-family: "Source Sans Pro", sans-serif;

  font-size: 16px;
  font-weight: 400;
  color: #313131;

  margin: 0;
}

.input {
  display: flex;
  height: 50px;
  padding: var(--spacing-spacing-xs, 15px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;

  border: unset;
  border-radius: 2px;
  background-color: #f4f5f7;

  width: 100%;

  @media screen and (breakpoints.$sendistovanTablet) {
    margin: 0;
  }

  &:focus {
    outline: 2px solid #009fff;
  }

  &.error {
    outline: 2px solid red;
  }
}
