@import "src/colorVariables.scss";

.with-tooltip,
.with-tooltip-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.with-tooltip-2 {
  margin-bottom: 16px;

  justify-content: flex-start;

  gap: 10px;
}

.tooltip-icon {
}

.tooltip-card {
  padding: 15px;
  max-width: 450px;

  width: 100%;

  font-size: 16px;

  text-align: justify;
}

.new-place {
  height: 100%;

  .close-place {
    position: absolute;
    right: 20px;
    top: 6px;
    font-size: 24px;
    cursor: pointer;
  }
  .w-content {
    height: calc(100% - 130px);
    overflow: auto;

    .new-content {
      .btn_current_submitted {
        width: 100%;
        margin-bottom: 10px;
        .btn {
          width: 115px;
          font-size: 12px;
          padding: 0;
          margin: 0;
          margin-right: 15px;
          background-color: $light;
          cursor: pointer;
          color: black;
        }
        .choosen {
          color: white;
          background-color: orange;
        }
      }
      padding: 43px 60px;
      h1 {
        font-size: 30px;
        font-weight: 600;
      }
      .fill-form {
        margin-top: 50px;
      }
      .start-time {
        margin-top: 15px;
      }
    }

    .image-upload-block {
      height: 200px;
      max-height: 200px;
      cursor: pointer;
      .upload-box {
        height: 100%;
      }
      .image-container {
        margin: auto;
        border: 1px solid rgba(0, 0, 0, 0.06);
        height: 100%;
        position: relative;
        max-height: 400px;
        .before-image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 64px;
          color: $blue;
        }
        .pen {
          position: absolute;
          right: 15px;
          top: 15px;
          //color: $mid;
          opacity: 0.5;
        }
        img {
          width: 298px;
          height: 190px;
          object-fit: contain;
        }
      }
    }

    .enable_editing {
      cursor: pointer;
      width: 100%;
      margin-left: 28%;
      color: orange;
      svg {
        margin-left: 5px;
        font-size: 12px;
      }
      p {
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }

    .enable_editing_checkbox {
      width: 100%;
      p {
        color: orange;
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 5px;
      }
    }

    // .uploaded-images {
    //     display: flex;
    //     flex-wrap: wrap;
    //     .one-image {
    //         position: relative;
    //         height: 200px;
    //         width: calc(50% - 10px);
    //         // margin: 5px;
    //         margin-top: 0;
    //         img {
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         opacity: 1;
    //         transition: opacity 0.2s;
    //         pointer-events: none;
    //         }
    //         .remove-image {
    //         position: absolute;
    //         top: 3px;
    //         right: 10px;
    //         font-size: 20px;
    //         opacity: 0;
    //         cursor: pointer;
    //         transition: opacity 0.2s;
    //         }
    //         .move-image {
    //         position: absolute;
    //         top: 3px;
    //         right: 30px;
    //         font-size: 20px;
    //         opacity: 0;
    //         cursor: pointer;
    //         transition: opacity 0.2s;
    //         }
    //         // .text-wrap:has(.vfi-text-area.focus){
    //         //   opacity: 1;
    //         // }
    //         .text-wrap{
    //         position: absolute;
    //         left: 5%;
    //         right: 5%;
    //         height: 46%;
    //         transition: opacity 0.2s;
    //         .flag{
    //             object-fit: contain;
    //             display: inline-block;
    //             width: 30px;
    //             height: unset;
    //             opacity: 1;
    //         }
    //         .vfi-text-area{
    //             margin-top: 1%;
    //             background-color: white;
    //             height: 49px;
    //             font-size: 12px;
    //             .ck-editor{
    //             height: 100%;
    //             .ck-editor__main{
    //                 height: 100%;
    //                 .ck-content{
    //                 height: 100%;
    //                 p:first-child{margin-top: 5px;}
    //                 }
    //             }
    //             }
    //         }
    //         }
    //         .text-wrap.hidden{
    //         opacity: 0;
    //         }
    //         .text-en{
    //         top: 30px;
    //         }
    //         .text-fo{
    //         top: 115px;
    //         }
    //     }
    //     .one-image:hover {
    //         img {
    //         opacity: 0.6;
    //         }
    //         .remove-image {
    //         opacity: 1;
    //         }
    //         .move-image {
    //         opacity: 1;
    //         }
    //         .text-wrap{
    //         opacity: 1;
    //         }
    //     }
    // }

    .uploaded-images {
      .one-image {
        position: relative;
        margin-top: 0;
        margin-bottom: 40px;
        box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);
        // transition: ease-in-out 0.5s;
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.2s;
          // pointer-events: none;
        }
        .drag-element {
          position: absolute;
          height: 200px;
          width: 100%;
          left: 0;
          top: 0;
          cursor: move;
        }
        .remove-image {
          position: absolute;
          top: 3px;
          right: 25px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .move-image {
          position: absolute;
          top: 3px;
          right: 45px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .text-wrap {
          margin-top: 15px;
          .flag {
            object-fit: contain;
            display: inline-block;
            width: 30px;
            height: unset;
            opacity: 1;
            margin: 5px 0 2px;
            pointer-events: none;
          }
        }
        .text-en {
          top: 30px;
        }
        .text-fo {
          top: 115px;
        }
      }
      .one-image:hover {
        img {
          opacity: 0.6;
        }
        .remove-image {
          opacity: 1;
        }
        .move-image {
          opacity: 1;
        }
        .text-wrap {
          opacity: 1;
        }
      }
      .one-image.dragging {
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        // display: none;

        // max-width: 0px;
        opacity: 0;
        overflow: hidden;
        // padding: 0;
      }

      .one-image.dragging-on {
        background-color: $light;
      }
    }

    // .new-content.one {
    // }

    .new-content.two {
      .place-categories {
        list-style: none;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        white-space: nowrap;
        margin: 60px auto 40px 0;
        padding: 0;
        // display: flex;
        // height: 360px;
        // flex-flow: wrap column;
        // align-items: flex-end;
        width: 60%;
        // text-align: center;
        .group {
          width: 30%;
          > label {
            display: flex;
            align-items: center;
          }
          .text {
            margin-left: 30px;

            .fo-category-extra,
            .en-category-extra {
              color: $mid;
              opacity: 0.5;

              font-style: italic;

              font-size: 15px;
            }
          }
          label {
            user-select: none;
            cursor: pointer;
          }

          transition: 0.2s;

          &.disabled {
            pointer-events: none;

            color: rgba(0, 0, 0, 0.5);

            filter: grayscale(1);
          }
        }
      }
      .add-category {
        display: flex;
        // justify-content: center;
        align-items: center;
        .label {
          margin-right: 20px;
        }
        .add-button {
          color: $green;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }

    .new-content.three {
      .opening-hours {
        //width: 80%;
        .table-wrap {
          width: max-content;
          margin: auto;

          .from,
          .to {
            .css-2b097c-container {
              display: inline-block;
              width: 125px;
              padding: 0 5px;
            }
            .css-14jk2my-container {
              display: inline-block;
              width: 125px;
              padding: 0 5px;
            }
          }
        }
      }
    }

    .new-content.four {
      .min-age,
      .capacity {
        align-items: center;
        p {
          margin: 0;
        }
      }
    }

    .new-content.five {
      .add-new-media-content {
        width: 50%;
        .add-new-media {
          display: inline-block;
          background-color: #009fff;
          color: white;
          vertical-align: middle;
          font-size: 17px;
          font-weight: 600;
          height: 50px;
          width: 160px;
          position: relative;
          cursor: pointer;
          margin-left: 5px;
          .text {
            width: 160px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }
        }
        .upload-box {
          margin-top: 30px;
          height: 150px;
          border: 2px dashed rgba(162, 162, 162, 0.5);
          position: relative;
          overflow: hidden;
          transition: height 0.5s;
          .text-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .first {
              text-align: center;
              font-size: 18px;
              font-weight: 600;
            }
            .second {
              text-align: center;
              font-size: 14px;
              color: #a2a2a2;
            }
          }
        }

        .upload-box.hidden {
          height: 0;
          border: 0;
        }
      }
      .edit-contents {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        .image-upload-block {
          display: inline-block;
          cursor: pointer;
          .image-container {
            border: 1px solid $mid;
            width: 300px;
            height: 192px;
            position: relative;
            .before-image {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 64px;
              color: $blue;
            }
            .pen {
              position: absolute;
              right: 15px;
              top: 15px;
              //color: $mid;
              opacity: 0.5;
            }
            img {
              width: 298px;
              height: 190px;
              object-fit: contain;
            }
          }
        }
      }
    }

    .new-content.six {
      .search-box {
        &.error {
          .vfi-input-text {
            border: 2px solid $red;
            border-radius: 2px;
          }
        }
      }
      .edit-contents {
        p {
          margin: 15px 0 0 0;
        }
      }
    }

    .new-content.seven {
      .edit-contents {
        margin-top: 50px;
        .contact-content {
          margin-bottom: 15px;
          align-items: center;
          p {
            // text-align: right;
            margin: 0;
          }
        }
      }
    }
    .new-content.eight {
      .new-edit {
        margin-top: 60px;
        .final-text {
          display: flex;
          //align-items: center;
          justify-content: space-around;
          width: 50%;
          margin: auto;
          .wooh {
            color: $blue;
            font-size: 75px;
            width: 20%;
          }
          p {
            font-size: 22px;
            font-weight: 600;
            color: $mid;
            width: 50%;
          }
          margin-bottom: 40px;
        }
        .post-button {
          background-color: $blue;
          width: min-content;
          margin: auto;
          padding: 10px 50px;
          color: white;
          font-weight: 600;
          font-size: 28px;
          cursor: pointer;
          user-select: none;
          transition: ease-in-out 0.2s;
        }
        .post-button:hover {
          background-color: $light-blue;
        }
      }
    }
  }
}
