@import "src/colorVariables.scss";

.activity-content,
.overlay {
  height: 100%;
  overflow-y: auto;
  .activity-display {
    padding: 57px 40px 0 40px;
    .header-panel {
      .header-title {
        display: inline-block;
        width: 50%;
        h1 {
          font-size: 30px;
          opacity: 0.98;
          font-weight: bold;
        }
      }

      .topright-panel {
        display: inline-block;
        width: 50%;
        text-align: right;
        .panel-list-parent {
          width: 100%;
          display: inline-block;
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            float: left;
            color: #a2a2a2;
            width: 20%;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            svg {
              color: $dark;
              font-size: 10px;
            }
          }
        }

        // .add-new-user{
        //     display: inline-block;
        //     background-color: #009FFF;
        //     color: white;
        //     vertical-align: middle;
        //     font-size: 17px;
        //     font-weight: 600;
        //     height: 50px;
        //     width: 160px;
        //     position: relative;
        //     cursor: pointer;
        //     .text{
        //         width: 160px;
        //         position: absolute;
        //         top: 50%;
        //         left: 50%;
        //         transform: translate(-50%, -50%);
        //         text-align: center;
        //     }
        // }
      }

      .search-parent {
        display: inline-block;
        width: 50%;
        margin-top: 20px;
        .search {
          position: relative;
          width: 50%;
          input {
            width: 100%;

            ::placeholder {
              font-size: 2px;
            }
          }
          .magnifying-glass {
            position: absolute;
            top: 45%;
            right: 12px;
            transform: translateY(-50%);
            pointer-events: none;
          }
        }
      }

      .all-sort-and-filter {
        display: inline-block;
        width: 50%;
        text-align: right;
        // margin-top: 20px;
        .image-show,
        .list-show,
        .filter-button {
          cursor: pointer;
          display: inline-block;
          margin-left: 35px;
          padding: 5px 10px;
          transition: ease-in-out 0.5s;
          border-radius: 2px;
          .icon {
            margin: auto;
          }
        }

        .image-show.selected,
        .list-show.selected {
          color: #009fff;
        }

        .filter-button {
          padding: 9px 15px 9px 20px;
          border: 1px solid transparent;
          border-radius: 20px;
          user-select: none;
          background-color: $blue;
          color: white;
          .slide-icon {
            margin-left: 15px;
          }
        }
        .filter-button.hidden {
          padding: 9px 15px 9px 20px;
          border: 1px solid rgba(162, 162, 162, 0.1);
          border-radius: 20px;
          user-select: none;
          background-color: white;
          color: black;
          cursor: pointer;
          .slide-icon {
            margin-left: 15px;
          }
        }
        .filter-button.hidden {
          padding: 9px 15px 9px 20px;
          border: 1px solid rgba(162, 162, 162, 0.1);
          border-radius: 20px;
          user-select: none;
          cursor: pointer;
          .slide-icon {
            margin-left: 15px;
          }
        }
      }
    }
    .table-select {
      margin-top: 20px;
      display: flex;
      .t-option {
        user-select: none;
        font-size: 14px;
        color: $mid;
        padding: 14px 15px;
        border-bottom: 1px solid rgba(162, 162, 162, 0.3);
        cursor: pointer;
        &.active {
          color: black;
          border-color: black;
        }
        &.rest {
          flex-grow: 1;
          cursor: auto;
        }
      }
    }
    .filter-what {
      margin-top: 30px;
      display: flex;
      gap: 10px;
      .f-option {
        display: flex;
        cursor: pointer;
        user-select: none;
        .f-text {
          transition: ease-out 0.2s;
        }
        &.active .f-text {
          color: $blue;
        }
        .notify {
          font-size: 10px;
          color: white;
          height: 14px;
          min-width: 20px;
          border-radius: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 1px;
          &.new-notify {
            background-color: black;
          }
          &.update-notify {
            background-color: $mid;
          }
        }
      }
    }
    .before-table {
      border-bottom: 1px solid;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      margin-top: 30px;
      .text-before-table {
        font-size: 19px;
        font-weight: 600;
        .amount-text {
          margin-left: 5px;
          font-style: italic;
        }
        .hidden-text {
          margin-left: 10px;
          font-style: italic;
          color: $mid;
        }
      }
      .hide-button {
        font-size: 15px;
        text-decoration: underline;
        user-select: none;
        cursor: pointer;
        padding-right: 10px;
      }
    }
    .activity-table {
      width: 100%;
      // margin-bottom:40px;
      margin-top: 20px;
      label {
        margin-bottom: 0;
      }
      thead {
        th {
          font-size: 14px;
          font-weight: 500;
          color: $mid;
          opacity: 0.98;
          padding: 10px 0;
          user-select: none;
          transition: ease-in-out 0.2s;
          svg {
            margin-left: 9px;
          }
        }
        th.post,
        th.site,
        th.activity,
        th.date,
        th.location {
          cursor: pointer;
        }
        th.page {
          width: 55%;
        }
        th.site,
        th.activity,
        th.location {
          width: calc((45% - 8%) / 3);
        }

        th.date {
          // width: 8%;
        }

        th:hover {
          color: #626262;
        }
        th.sorted-by {
          color: black;
        }
      }

      tbody {
        tr {
          padding: 0;
          margin: 0;

          &:hover {
            td {
              background-color: $light;
            }
          }

          td {
            padding: 9px 0;
            height: 60px;
            font-size: 14px;
            border-top: 1px solid rgba(162, 162, 162, 0.1);
          }

          .page {
            cursor: pointer;
            img {
              height: 100%;
              width: 70px;
              margin: auto;
              object-fit: cover;
              margin-right: 2%;
              float: left;
            }
            .gray_small {
              font-size: 11px;
              color: $mid;
              font-weight: normal;
            }
            p {
              margin: 0;
              padding: 0;
              // padding-top: 4px;
            }
          }

          td.expand {
            // display: none;
            width: 40px;
            text-align: center;
            cursor: pointer;
          }

          td.Date {
            font-size: 13px;
            color: $mid;
          }

          td.Activity {
            svg {
              margin-right: 5%;
            }
          }

          td.Approval {
            padding-right: 40px;
            width: 300px;
            .approval-wrap {
              > div {
                padding: 5px 10px;
                font-size: 14px;
              }
            }

            .change-decision-button {
              text-decoration: underline;
              color: $mid;
              cursor: pointer;
            }
          }

          &.main-tr-expanded {
            background-color: $grey-menu;
          }

          &.expanded {
            background-color: rgba(244, 245, 247, 0.5);
            &.head {
              color: $mid;
            }
            &.show-more {
              cursor: pointer;
              user-select: none;
              background-color: rgba($color: $mid, $alpha: 0.15);
              td {
                height: unset;
              }
            }
          }
        }

        tr.loading-data {
          &.hidden {
            display: none;
          }
          td {
            border-top: none;
            text-align: center;
            font-size: large;
          }
        }
        .hide {
          display: none;
        }
      }
    }
    .SeeMore {
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 10px;
      width: 80px;
      svg {
        font-size: 10px;
        margin-left: 5px;
        margin-bottom: 1px;
      }
    }
    .right-side-info {
      width: 390px;
      .close {
        right: unset;
        left: 20px;
        opacity: 1;
        svg {
          color: white;
        }
      }
      input::placeholder,
      input {
        color: white;
      }
      svg {
        color: $mid;
      }
      .search-box {
        margin-bottom: 20px;
      }
      .dropdown-option {
        border-top: 1px solid rgba($color: $mid, $alpha: 0.1);
        .drop-title {
          padding: 10px 0;
          margin-bottom: 0;
          font-size: 12px;
        }
        .filter-option-content {
          padding: unset;
          label {
            margin-bottom: 0;
          }
          .date-from-to {
            display: flex;
            gap: 20px;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
            label {
              svg {
                color: #626262;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .react-datepicker-wrapper,
            .to-text {
              flex: 1 1 auto;
            }
            .to-text {
              color: $mid;
            }
            .react-datepicker-wrapper input {
              padding: 10px 30px 10px 15px;
              background-color: rgba(162, 162, 162, 0.05);
              border: none;
              border-radius: 2px;
              border: 2px solid transparent;
              width: 100%;
            }
          }
        }
        .filters {
          .filter {
            margin-bottom: 10px;

            .text {
              margin-left: 16px;
              color: $mid;
              &.active {
                color: white;
              }
            }
          }
        }
        &.collapsed .filter-option-content {
          height: 0;
        }
      }
    }
  }
  .over-all {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $mid, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .over-all-content {
      // width: 340px;
      padding: 30px;
      background-color: white;
      max-width: 475px;
      .oa-head {
        display: flex;
        justify-content: space-between;
        svg {
          cursor: pointer;
        }
      }
      .oa-subtext {
        font-size: 0.875rem;
        margin-top: 10px;
        margin-bottom: 30px;
      }
      .oa-approval {
        color: $mid;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 8px;
        font-weight: 600;
      }
      .oa-select {
        .vfi-custom-select-wrap {
          width: 280px;
        }
      }
      .oa-mail-text {
        width: 415px;
        .ck-content {
          height: 150px;
          background-color: $light;
        }
      }
      .oa-buttons {
        margin-top: 25px;
        display: flex;
        gap: 15px;
        justify-content: flex-end;
        .cancel-button,
        .save-button {
          flex: 1 1 0;
          font-size: 12px;
          border-width: 0;
          max-width: 140px;
          border-radius: 4px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.4px;
          &:disabled {
            opacity: 0.25;
          }
        }
        .cancel-button {
          background-color: $light;
        }
        .save-button {
          background-color: $blue;
          color: white;
        }
      }
    }
  }
  #media-settings {
    padding: 0 20px;
  }
}
