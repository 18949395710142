
.container {
 
 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    gap: 20px;

    .wrapper {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        gap: 5px;

        &.single {
                align-items: center;
        }

        .title {
            text-transform: uppercase;

            font-size: 15px;
        }

        .elements {

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            gap: 10px;

            .element {
                padding: 15px 25px;

                border: 1px solid #000;
                border-radius: 1000px;

                background-color: #fff;

                color: #000;

                text-transform: capitalize;

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
    
                transition: 0.1s;
    
                &.selected {
                    background-color: #000;

                    color: #fff;
                }
            }
        }
    }
}