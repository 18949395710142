.container {
  padding-bottom: 6px;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 12px;

  position: relative;

  p {
    font-family: "Source Sans Pro";
  }

  .label {
    display: flex;

    p {
      padding: 0;
      margin: 0;

      font-size: 15px;
      line-height: 19px;

      font-weight: 600;
    }
  }

  .input_wrapper {
    width: 100%;

    position: relative;

    input {
      padding: 14px;

      width: 100%;

      border: none;

      border-radius: 2px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.3) !important;
        font-family: "Source Sans Pro";
        font-weight: 400;
      }

      &:focus {
        outline: 2px solid #009fff;
      }

      &.error {
        outline: 2px solid #f64e60;
      }

      &.confirmed {
        outline: 2px solid #7ecf8f;
      }
    }

    .checkmark,
    .exclamation {
      font-size: 16px;

      position: absolute;
      bottom: 50%;
      right: 20px;

      transform: translateY(50%);
    }

    .exclamation {
      color: #ef2533;
    }

    .checkmark {
      color: #7ecf8f;
    }

    .error_message {

      padding: 0;
      margin: 0;

      font-size: 13px;
      color: #ef2533;

      position: absolute;
      bottom: -24px;
      left: 0;
    }
  }
}
