@use "../../../breakpointVariables.scss" as breakpoints;
@import "src/colorVariables.scss";


.submit-entity {
  .bottom-submit-wrap {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    .preview-whatson {
      background-color: transparent;
      font-size: 0.875rem;
    }
    .whatson-navigation-in-submit {
      position: static;
      padding-top: 0;
      @media (breakpoints.$sendistovanMobile) {
        display: none;
      }
    }
  }
  .hold-on-bullets {
    width: fit-content;
    margin: auto;
    text-align: left;
  }
  h3 {
    margin-bottom: 20px;
    margin-top: 60px;
    text-align: center;
  }
  p {
    line-height: normal;
    text-align: center;
  }

}

.success-view {
  .goose-image {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    height: 400px;
  }
  .complete-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    margin-top: 70px;
    .edit {
      padding: 0 1.875rem;
      background-color: transparent;
      font-size: 0.875rem;
    }
    .blue,
    .gray {
      padding: 0 24px;
      border-radius: 4px;
      height: 3.125rem;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 2px;
      flex-basis: 13rem;
      text-transform: uppercase;
    }
    .blue {
      background-color: $blue;
      font-weight: 600;
      color: white;
    }
    .gray {
      background-color: $light;
      font-weight: 700;
      color: $text-color;
    }
  }
}
