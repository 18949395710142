@use "../../../../../../../breakpointVariables" as breakpoints;

$element_max_width: 335px;

.container {
 
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: flex-start;
    align-content: center;

    gap: 24px;

    @media (breakpoints.$sendistovanTablet) {
        grid-template-columns: 1fr 1fr;
    }

    @media (breakpoints.$sendistovanMobile) {

        width: 100%;
        grid-template-columns: 1fr;

        justify-items: center;
        align-content: center;
    }

    .image_element {

        width: 100%;
        max-width: $element_max_width;
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        gap: 16px;
        
        .image_container {
            
            width: 100%;
            aspect-ratio: 1 / 1;
            
            img,
            .image {
                padding: 0;
                margin: 0;

                width: 100%;
                max-width: $element_max_width;
                height: 100%;
                height: $element_max_width;

                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
               
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
    
                gap: 8px;
    
                color: #fff;
                
                text-transform: uppercase;
    
                user-select: none;
    
                cursor: pointer;
                
                &.empty {
    
                    background-color: #373737;
    
                }

                &.error {
                    outline: rgba(255, 0, 0, 0.5) solid 2px;
                }
            }
    
        }

        .input_container {
            width: 100%;
            max-width: $element_max_width;

            transition: 0.1s opacity;
        }
    }


}