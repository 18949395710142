@use "../../../../../../../breakpointVariables" as breakpoints;

.container {

    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 24px;

    .instagram_input {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        gap: 4px;

        .label {
            width: 100%;

            text-align: left;
        }

    }

    .first_section {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        gap: 4px;

        .title {
    
            font-size: 12px;

            color: #ACACAC;

            font-weight: 600;

            line-height: 15px;
    
            user-select: none;
        }
    }

    .content {

        padding-bottom: 124px;

        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        gap: 24px;

        transition: 0.1s;

        .information {
            text-align: left;
        }
    
        .profile_links {

            width: 100%;
    
            display: grid;
            grid-template-columns: 1fr 1fr;
    
            gap: 8px;

            .instagram_input {
                width: 100%;
            }
    
        }
    
        .image_display {

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
    
            grid-area: 16px;

            @media (breakpoints.$sendistovanMobile) {
              width: 100%;        
            }
        }

        .image_elements {
            width: 100%;
        }
    }


}


.image_amount {
    width: 100%;
    
    max-width: 400px;

    @media (breakpoints.$sendistovanMobile) {
        max-width: 100%;
    }

}