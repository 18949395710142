@import "src/colorVariables.scss";

.main {
  .navigation.collapsed {
    min-width: 80px;
    .option-text {
      visibility: hidden;
    }
  }
  .navigation {
    transition: ease-in-out 0.5s;
    width: 200px;
    min-width: 200px;
    position: relative;

    .nav-menu-parent {
      position: relative;

      user-select: none;
      .option-text {
        position: absolute;
        top: 50%;
        left: 25px;
        transform: translateY(-50%);
      }
      .icon {
        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        .popUp {
          background-color: $dark-blue;
          position: absolute;
          top: 10px;
          right: 3px;
          width: 45%;
          margin: 0;
          height: 25%;
          border-radius: 40%;
          font-size: 11px;
          color: white;
          transform: translate(-50%);
          text-align: center;
        }
      }

      .image {
        img {
          width: 25px;
          height: 25px;
          object-fit: cover;
          // width: 40px;
          border-radius: 50%;
        }
      }
    }
    .user {
      position: absolute;
      bottom: 25px;
    }

    .userPopUp {
      position: fixed;
      z-index: 1;
      bottom: 80px;
      width: 340px;
      height: 230px;
      background-color: white;
      display: block;
      padding-left: 34px;
      padding-right: 34px;
      padding-top: 34px;
      border-radius: 2px;
      // position: relative;

      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.11);

      .info {
        // float: left;
        // width: 55%;
        height: 74px;
        p {
          margin: 0;
          padding: 0;

          &.textBlack {
            color: Black;
            font-size: 16px;
            line-height: 24px;
            // font-weight: 600;

            position: relative;
            top: -3px;
          }

          &.textLight {
            color: $mid;
            font-size: 14px;
            line-height: 18px;

            position: relative;
            top: -8px;
          }
        }
        .btn {
          //   margin: 8px 0 0 0;
          height: 30px;
          width: 115px;
          background-color: $blue;
          position: relative;
          p {
            color: white;
            font-weight: 300;
            font-size: 13px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .image {
        width: 74px;
        height: 74px;
        margin: 0;
        margin-right: 18px;
        padding: 0;
        float: left;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .signOut-container {
        position: absolute;
        bottom: 32px;
        left: 32px;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        padding-top: 12px;
        width: 272px;
        border-top: 1px solid rgba(162, 162, 162, 0.1);

        .signOut-btn,
        .settings-btn {
          color: $dark;
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
    .hidden {
      display: none;
    }
  }

  .navigation.collapsed {
    width: 80px;
    transition: ease-in-out 0.5s;
  }

  .logout {
    cursor: pointer;
  }
}

.navigation {
  background-color: rgb(5, 5, 5);
  vertical-align: top;
  padding: 0 22px;

  .top-logo {
    cursor: pointer;
    position: relative;
    transition: padding 0.5s, margin 0.5s;
    padding-top: 49px;
    margin-bottom: 30px;
    img {
      height: 25px;
    }
    .invertLogo {
      filter: invert(1);
    }
    .icon {
      color: #009fff;
      position: absolute;
      right: 0;
      font-size: 13px;
      top: 57px;
      transform: rotate(-180deg);
      transition: all 0.3s linear;
    }
  }

  .nav-menu-parent {
    width: fit-content;
    height: 56px;
    display: table;
    .nav-menu {
      display: table-cell;
      vertical-align: middle;
      width: max-content;
      // margin: 32px 0 0 0;
      padding: 5px 5px;
      text-align: left;
      background-color: transparent;
      color: rgba(162, 162, 162, 0.5);
      //transition: background-color 0.5s;
      transition: all 0.5s ease-in-out;
      border-radius: 2px;
      cursor: pointer;
      .icon {
        font-size: medium;
        width: 30px;
        height: 30px;
        vertical-align: middle;
        .popUp {
          box-sizing: border-box;

          padding: 2px;

          font-size: 10px;

          width: 16px;
          height: 16px;
          
          aspect-ratio: 1/1;
          
          color: white;

          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 1000px;

          top: 5px;
          
        }
        img {
          width: 100%;
          height: 100%;
          filter: brightness(0) invert(1);
        }
      }
      .option-text {
        overflow-x: hidden;
        display: inline-block;
        margin: 0 0 0 10px;
        width: 100px;
        font-size: 1em;
        font-weight: 400;
        transition: ease-in-out 0.5s;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }

  .nav-menu.selected {
    // background-color: white;
    color: white;
  }
}

.navigation.collapsed {
  .top-logo {
    .icon {
      visibility: hidden;
      opacity: 0;
    }
  }

  .top-logo {
    // margin: 40px 0px 0 0;
  }

  .nav-menu {
    .option-text {
      width: 0;
      margin: 0;
      color: transparent;
    }
  }
}
