@import "src/colorVariables.scss";
.container {
  padding: 200px;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 24px;

  overflow: auto;

  .seperator {
    max-width: 400px;
    width: 100%;

    display: none; // Temporarily removed as we can't logi nwith facebook
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 20px;

    color: #a2a2a25e;

    &::before,
    &::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid #a2a2a25e;
    }

    visibility: hidden;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    gap: 16px;

    .title {
      width: 100%;
      text-align: left;

      font-size: 20px;
      text-transform: uppercase;

      font-weight: 700;

      font-family: "Source Sans Pro";
    }

    .section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        gap: 8px;

        p {
          padding: 0;
          margin: 0;
          text-align: left;

          font-weight: bold;

          font-size: 13px;

          font-weight: 500;
        }

        .input {
          width: 400px;
        }

        .phone_inputs {
          width: 400px;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .input {
            width: 300px;
          }

          .code {
            height: 100%;

            .country_code {
              height: 100%;
            }

            svg {
              transform: translateY(-8px) !important;
            }
          }
        }

        &.terms_and_conditions {
          width: 400px;
        }
      }

      &.email,
      &.optional {
        gap: 16px;
      }
    }

    .register {
      transition: 0.2s;

      .loading_text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: 8px;
      }

      &.loading {
        filter: grayscale(0.1);
        opacity: 0.9;
      }
    }
  }

  .country_code_menu {
    width: 180px;
    height: fit-content;

    transform: translateX(-25%);

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .login_button {
    width: fit-content;
    height: fit-content;

    position: absolute;
    top: 70px;
    right: 50px;
    background-color: transparent;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 8px;

    @media ($phone) {
      top: 20px;
      right: 20px;
    }
    p {
      margin: 0;
      padding: 0;

      font-weight: 700;
      font-size: 0.938rem;
      font-family: $font-source-sans-pro;
    }

    svg {
      font-size: 1.063rem;
    }

    &:hover {
      p {
        text-decoration: underline;
      }
    }
  }
}

.popout_error {
  padding: 16px 32px 16px 16px;

  min-width: 90px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 8px;

  background-color: rgba(255, 0, 0, 0.8);

  font-size: 13px;
  font-weight: 500;
}
