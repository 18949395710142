@use "../../../../../../colorVariables" as colors;
@use "../../../../../../breakpointVariables" as breakpoints;

.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;

  overflow: auto;

.title_wrapper {
  width: 100%;
  
  background-color: #f7f7f7;

}

  .title {
    width: 100%;
    max-width: 1404px;

    margin: 0 auto;
    padding: 30px 0;
    text-align: start;

    font-size: 42px;
    text-transform: uppercase;
    font-family: "Source Sans Pro";
    font-weight: 700;


    letter-spacing: 5.3px;

    @media screen and (breakpoints.$sendistovanTablet) {
      padding: 30px 20px;

      font-size: 26px;
      line-height: 30px;
      letter-spacing: 2px;
    }
  }

  .content {
    background-color: white;

    width: 100%;
    max-width: 1820px;

    min-height: calc(100% - 90px);
    
    @media screen and (breakpoints.$sendistovanTabletReverse) {
      // min-height: calc(100% - 90px);
      height: fit-content;
    }

    margin: 0 auto;
  }
}
