@import "src/colorVariables.scss";

// .whatson-content{
.whatson-navigation {
  position: absolute;
  z-index: 2;
  right: 80px;
  // top: 139px;
  // padding-top: 32px;

  .preview-container,
  .save-draft-container,
  .undo,
  .redo,
  .back,
  .forward {
    display: inline-block;
    vertical-align: middle;
    user-select: none;
  }

  .preview-container,
  .save-draft-container,
  .undo,
  .redo {
    cursor: pointer;
  }

  .undo,
  .redo {
    color: $mid;
    margin-left: 20px;
  }
  .controls {
    display: inline-block;
    margin-left: 20px;
    .back,
    .forward {
      margin-left: 20px;
      width: 90px;
      height: 50px;
      border-radius: 4px;
      position: relative;

      text-transform: uppercase;

      
      letter-spacing: 2px;
      
      line-height: 21px;
      
      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 13px;
        // font-size: 1.063rem;
      }

      &.whatson-front {
        width: 150px;

        display: flex;
        justify-content: center;
        align-items: center;

        span {
          position: initial;

          left: initial;
          top: initial;

          transform: initial;
        }
      }

      &.disabled {
        background-color: $light;
        color: $mid;
      }
    }
    .back {
      background-color: $light;
      color: $dark;

      font-weight: bold;
    }
    .forward {
      background-color: $blue;
      color: white;
      font-weight: 600;
    }
  }

  .save-draft-container,
  .preview-container {
    background-color: white;
    transition: ease-in-out 0.2s;
    border-radius: 2px;
    .save-draft,
    .preview {
      font-size: 0.875rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // font-size: 17px;
      width: 100%;
      text-align: center;
    }
    &:hover {
      background-color: $lighter;
      .save-draft,
      .preview {
        //color: $light-blue;
        text-decoration: underline;
      }
    }

    margin-left: 20px;
    width: 90px;
    height: 50px;
    border-radius: 2px;
    position: relative;
    background-color: white;
  }

  .hidden {
    display: none;
  }

  @media ($phone) {
    padding-top: 0;
    right: 20px;
    top: 149px;
    .controls {
      .back,
      .forward {
        // height: 40px;
        span {
          font-size: 1rem;
        }
      }
    }
  }
}
// }
