

.container {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    grid-gap: 24px;

    p {
        margin: 0;
        padding: 0;
    }

    .input {
        max-width: 260px;

        text-align: center;
    }

    .verify {
        width: 160px;

        .loading_text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
    
            gap: 8px;
        }
    }
}

.popout_error {
    padding: 16px 32px 16px 16px;

    min-width: 90px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    gap: 8px;

    background-color: rgba(255, 0, 0, 0.8);

    font-size: 13px;
    font-weight: 500;
}