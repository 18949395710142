@use "../../../../../breakpointVariables" as breakpoints;

.toggle {

    padding: 40px 0 20px;
    
    @media (breakpoints.$sendistovanTablet) {
        padding: 2px 20px 40px;
    }

    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}