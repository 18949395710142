
$form_max_width: 440px;

.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form {

    padding: 0 16px;

    width: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 20px;

    position: relative;

    .logo {

      width: fit-content;

      position: absolute;
      top: -80px;
      left: 50%;
      transform: translateX(-50%);
    }

    h3 {
      padding: 0;
      margin: 0;

      font-family: "Source Sans Pro";

      font-size: 20px;

      line-height: 25px;

      letter-spacing: 0.5px;

      text-transform: uppercase;

      font-weight: bold;
    }

    .input_container {
      max-width: $form_max_width;
    }

    .input {
      width: 100%;
      max-width: $form_max_width;

      background-color: #fafafa;

      .name {
      }

      .email {
      }
    }

    .submit_button {
      width: 100%;
      max-width: $form_max_width;
    }
  }
}
