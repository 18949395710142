@import "src/colorVariables.scss";
.opening-hours {
  // max-width: 660px;
  max-width: 1020px;
  padding-top: 3rem;
  .opening-hours-wrap {
    display: grid;
    grid-template-columns:
      minmax(170px, 270px) 10px minmax(170px, 270px) minmax(10px, 80px) min-content minmax(10px, 90px)
      min-content 20px auto;
    grid-template-rows: auto auto;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    @media ($phone) {
      flex-direction: column;
      margin-bottom: 40px;
      align-items: flex-start;
      gap: 4px;
    }
    .element {
      padding: 20px 0;
      &.no-padding-top {
        padding-top: 0;
      }
    }
    .title {
      padding-bottom: 14px;
    }
    .day {
    }
    .time-from-to {
      .time-from-to-wrap {
        transition: ease-out 0.2s;
        &.hidden {
          opacity: 0;
          visibility: hidden;
        }
      }
      .from,
      .to {
        display: flex;
        align-items: center;
        .text {
          width: 65px;
        }
        .vfi-custom-select {
          width: 60px;
          margin-right: 5px;
          .css-1s2u09g-control,
          .css-1pahdxg-control {
            background-color: $light;
            border: 2px solid transparent;
            margin-bottom: 2px;
            box-shadow: none;
          }
          .css-1pahdxg-control {
            border: 2px solid $blue;
          }
        }
      }
    }
    .closed {
      .closed-label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding-top: 14px;
        .closed-text {
          padding-left: 12px;
        }
        label {
          margin-bottom: 0;
          height: 20px;
        }
      }
    }
    .delete {
      // position: absolute;
      right: -20px;
      top: 50%;
      font-size: 14px;
      width: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      // padding-left: 20px;
      button {
        background-color: transparent;
      }
    }
    .underline {
      grid-column: 1 / span 8;
      border-bottom: 1px solid rgba(162, 162, 162, 0.25);
      &.inactive {
        border-bottom: 1px solid transparent;
      }
    }
    .no-underline {
      border-bottom: 1px solid transparent;
    }
  }
  .add-icon {
    cursor: pointer;
    user-select: none;
    width: 100px;
    text-align: center;
    margin: auto;
    margin-top: 10px;
    text-decoration: underline;
    span {
      margin-right: 12px;
    }
    &:hover {
      text-decoration: underline dotted;
    }
    svg {
      font-size: 16px;
    }
  }
}
