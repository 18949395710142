.instagram-in-whatson {
  margin-bottom: 100px;

  .upper-wrap {
    max-width: 670px;
    // .instagram-description {

    // }
    .edit-contents {
      opacity: 1;
      transition: opacity 0.5s;
    }

    .edit-contents.hidden {
      user-select: none;
      opacity: 0;
      pointer-events: none;
    }
    .row {
      margin: 35px 0 0 0;
      display: flex;
      flex-direction: column;
    }
    .hashtag,
    .profile-link {
      display: flex;
      gap: 35px;
      flex-direction: row;
      max-width: unset;
      width: 100%;
      padding: 0;
      align-items: center;
      margin-bottom: 20px;

      p {
        width: 150px;
        margin: 0;
      }

      .vfi-input-text {
        flex-grow: 1;
      }
    }
    .vfi-custom-select-wrap {
      .vfi-custom-select {
        .css-26l3qy-menu {
          z-index: 2;
        }
      }
    }
  }
  .instagram-image-links {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    .insta {
      width: 300px;
      display: inline-block;
      margin: 15px 30px 15px 0;
      &.hidden {
        opacity: 0;
      }
      .insta-image {
        width: 300px;
        height: 300px;
        position: relative;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
      .insta-link-wrap {
        margin-top: 5px;
        input::placeholder {
          font-size: 14px;
        }
        .insta-text {
          white-space: nowrap;
          margin-right: 5px;
        }
      }
    }
  }
}
