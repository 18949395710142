.greyout{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	background-color: #00000033;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.privacyPolicy{
		width: 350px;
		margin: 25px auto;
		background-color: white;
		.header{
			font-size: 1.2em;
			padding: 10px;
		}
		.content{
			padding: 25px;

			.acceptNotification, .errorNotification{
				font-style: italic;
				color: red;
			}
			.errorNotification{
				margin-top: 15px;
			}
			.checkField{
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 10px;
				.checkbox{
					display: inline;	
				}
			}
		}
		.cp{
			display: flex;
			flex-direction: row;
			justify-content: end;
			gap: 10px;
			padding: 15px;
		}
	}
}