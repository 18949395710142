@use "../../../../../breakpointVariables" as breakpoints;

.content {
  display: flex;
  justify-content: space-between;

  gap: 65px;
  margin-top: 88px;

  @media screen and (breakpoints.$sendistovanTablet) {
    margin-top: unset;

    flex-direction: column;
    padding: 0px 20px 80px;

    gap: 30px;
  }
}

.divider {
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  @media screen and (breakpoints.$sendistovanTablet) {
    display: none;
  }
}

.descriptionInputContainer {
  flex: 1;

  display: flex;
  flex-direction: column;

  gap: 37px;

  @media screen and (breakpoints.$sendistovanTablet) {
    gap: 20px;
  }

  .toggleContainer {
    display: flex;
    align-items: center;

    gap: 10px;

    @media screen and (breakpoints.$sendistovanTablet) {
      flex-direction: row-reverse;
      justify-content: start;
    }

    .icon {
      width: 36px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;

        vertical-align: unset;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.descriptionInputContent {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (breakpoints.$sendistovanTablet) {
    gap: unset;
  }

  > div {
    display: flex;
    justify-content: space-between;

    @media screen and (breakpoints.$sendistovanTablet) {
      flex-direction: column;
    }
  }
}

.inputTemplate {
  display: flex;

  align-items: center;
  justify-content: space-between;

  @media screen and (breakpoints.$sendistovanTablet) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.inputHeadlineContainer {
  display: flex;
  gap: 5px;

  
  margin-top: 15px;
  
  @media screen and (breakpoints.$sendistovanTablet) {
    // max-width: 85px;

    width: 100%;

    align-items: center;
    margin-top: unset;
  }
}

.inputHeadline {
  font-family: "Source Sans Pro", sans-serif;

  font-size: 16px;
  line-height: 17px;

  color: #313131;

  padding: 3px 0 0 0;
  margin: 0;

  @media screen and (breakpoints.$sendistovanTablet) {
    padding: 0;
  }
}

.tooltipIcon {
  height: fit-content;
}

.descriptionInputElement {
  display: flex;
  flex-direction: column;
  align-items: end;

  max-width: 415px;
  width: 100%;

  @media screen and (breakpoints.$sendistovanTablet) {
    max-width: unset;

    padding: 10px 0 4px 0;
  }

  input,
  textarea {
    background-color: rgba(162, 162, 162, 0.05);
  }

  .textLimitIndicator {
    width: fit-content;
    padding-top: 4px;

    color: #373737;
  }
}

.textLimitIndicator {
  font-family: "Source Sans Pro", sans-serif;

  font-size: 12px;
  font-style: italic;
}

.textLimitIndicatorPadding {
  padding-bottom: 22px;
}
