@use "../../../../../breakpointVariables" as breakpoints;

.content {
  display: flex;
  flex-direction: column;
  align-items: start;

  margin-top: 48px;
  gap: 50px;

  @media screen and (breakpoints.$sendistovanTablet) {
    justify-content: space-between;
    max-width: 100%;

    margin-top: unset;
    padding: 0px 20px 80px;
  }
}

.headlineTitle {
  text-align: left;

  font-family: "Source Sans Pro", sans-serif;

  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.label {
  font-size: 16px;
}

.priceContent {
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 100%;
}

.priceInputContainerWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.labelButtonContainer {
  display: flex;
  justify-content: center;

  max-width: 840px;

  @media screen and (breakpoints.$sendistovanTablet) {
    max-width: unset;
  }
}

.bookingUrlContainer {
  max-width: 840px;

  @media screen and (breakpoints.$sendistovanTablet) {
    max-width: unset;
  }
}

// --- PriceInput ---

.priceInputContainer {
  position: relative;
  width: fit-content;

  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 270px));
  grid-template-areas: "price english faroese";

  gap: 15px;

  @media screen and (breakpoints.$sendistovanTablet) {
    width: 100%;

    grid-template-columns: repeat(2, minmax(auto, 100%));
    grid-template-areas:
      "price price"
      "english faroese";
  }
}

.removePriceInput {
  position: absolute;

  top: 0;
  right: 0;
}
