
.container {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    gap: 10px;

    input[type='checkbox'] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        width: 14px; /* Width of the new checkbox */
        height: 14px; /* Height of the new checkbox */
        background-color: #fff; /* Background color */
        border: 2px solid rgba(162,162,162,0.3); /* Border color */
        // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        cursor: pointer; /* Cursor change on hover */

        display: block;

        cursor: pointer;

        &.error {
            outline: 2px solid rgba(255, 0, 0, 1);
        }
    }

    input[type='checkbox']:checked {
        background-color: #008DFF;
    }

    input[type='checkbox']:checked:after {
        content: '✓';
        color: white;
        font-size: 8px;
        font-weight: bold;
        display: block;
        text-align: center;
        transform: translateY(-1px);
    }

    a {
        text-decoration: underline;
    }

    a,
    p {
        padding: 0;
        margin: 0;

        color: #A2A2A2;

        font-family: "Source Sans Pro";

        font-size: 15px;
        line-height: 22px;
    }
}