@use "../../../../../breakpointVariables" as breakpoints;

.container {

  transition: opacity 0.1s ease-in-out;
  
  &.disabled {
    opacity: 0.5;
  
    pointer-events: none;
  }

}

.types_invalid_error_message {

  padding: 20px 0 0;
  
  text-align: left;

  color: #ff0000;
  
  @media (breakpoints.$sendistovanTablet) {
    padding: 0 20px 20px 20px;
  }
}

.content {
  max-width: 768px;
  display: flex;

  flex-wrap: wrap;
  margin-top: 44px;

  row-gap: 4px;

  @media screen and (breakpoints.$sendistovanTablet) {
    justify-content: space-between;
    max-width: 100%;

    margin-top: unset;
    padding: 0px 20px 80px;
  }
}

.TypeCheckboxContainer {
  display: flex;
  gap: 8px;

  flex: 50%;

  @media screen and (breakpoints.$sendistovanMobile) {
    flex: 100%;
  }
}

.secondaryLabel {
  font-size: "Source Sans Pro", sans-serif;
  font-size: 12px;
  line-height: 22px;

  
  font-style: italic;
  color: rgba(162, 162, 162, 0.5);
}