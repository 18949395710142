@import "src/colorVariables.scss";
.preview-display {
  padding: 0 0 0 30px;
  display: flex;
  height: 100%;
  background-color: #f4f4f5;
  width: 100%;
  .left-side {
    // background-color: lightblue;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    .header {
      height: 100px;
      .header-content {
        display: flex;
        .go-back {
          font-weight: 600;
          font-size: 19px;
          margin-right: 50px;
          cursor: pointer;
          transition: ease-in-out 0.2s;
          display: flex;
          align-items: center;
          gap: 8px;
          &:hover {
            color: $blue;
          }
        }
        .one-language {
          width: 70px;
          height: 50px;
          margin: 10px;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #a2a2a2;
          color: #a2a2a2;
          font-size: 14px;
          transition: ease-in-out 0.2s;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:disabled {
            opacity: 0.25;
            cursor: auto;
          }
          &.selected {
            border-bottom: 1px solid black;
            color: black;
          }
        }
      }
    }
    .display-section-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.11);
      margin-bottom: 30px;
      overflow: hidden;
      &.fill {
        flex-grow: 1;
      }
      .display-content-top {
        padding: 0px 10px;
        height: 50px;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        justify-content: space-between;
        .section-wrap {
          display: flex;
          align-items: center;
        }
        .edit-button {
          font-size: 14px;
          font-weight: 600;
          padding: 5px 4px;
          margin-left: 6px;
          cursor: pointer;
          transition: ease-out 0.2s;
          user-select: none;
          background-color: transparent;
          &:hover {
            background-color: $light;
          }
          &.disabled {
            color: $mid;
            pointer-events: none;
          }
        }
        a.external-link {
          color: black;
          padding: 5px 10px;
          &:hover {
            background-color: $light;
          }
        }
        .locked-info {
          font-size: 14px;
          color: $mid;
        }
      }
      .page-content {
        // height: 100%;
        flex-grow: 1;
        display: flex;
        position: relative;
        .overlay {
          height: 100%;
          width: calc(100% - 50px);
          left: 0;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.1);
        }
        .iframe {
          width: 100%;
          border-width: 0;
          // height: 100%;
          // pointer-events: none;
          overflow: auto;
          .main-content {
            display: none;
          }
        }
        &.aspect-16-9 {
          aspect-ratio: 16 / 9;
        }
      }
    }
  }
  .right-side {
    flex: 0 0 350px;
    height: 100%;
    margin-left: 5px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    @import "./GeneralOptions.scss";
    .options-section-header {
      height: 100px;
      display: flex;
      align-items: center;
      // flex-direction: column;
      flex: 0 0 auto;
      .option {
        flex-grow: 1;
        height: 50px;
        position: relative;
        border-bottom: 1px solid #a2a2a2;
        color: #a2a2a2;
        // margin: 0 5px;
        transition: ease-in-out 0.2s;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        white-space: nowrap;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected {
          color: black;
          border-bottom: 1px solid black;
        }
      }
    }
    .section {
      flex: 1 1 auto;
      // overflow: auto;
      margin-bottom: 30px;
    }
    button {
      height: 40px;
      width: 100%;
      border: none;
      background-color: rgba(162, 162, 162, 0.1);
      font-size: 14px;
      &:hover {
        background-color: rgba(162, 162, 162, 0.2);
      }
    }
  }
}
