@import "src/colorVariables.scss";

.with-tooltip,
.with-tooltip-2 {
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.with-tooltip-2 {
  margin-bottom: 16px;

  justify-content: flex-start;

  gap: 10px;

  &.no-margin {
    margin-bottom: 0;
  }
}

.tooltip-icon {
}

.tooltip-card {
  padding: 15px;
  max-width: 450px;

  width: 100%;

  font-size: 16px;

  text-align: justify;
}

.new-tour {
  height: 100%;

  // Common rules for single form groups or elements
  .single-form-group-container {
    flex: 0 0 100%;

    > h3 {
      font-size: 15px;
      opacity: 98%;
      text-transform: uppercase;
      margin: 50px 0 50px 15px;
    }

    > .form-group-part-desc {
      color: #a2a2a2;
      text-transform: uppercase;
      font-size: 12px;
      margin: 0 0 15px 15px;
    }

    > .language-box {
      > .two-col-field {
        display: grid;
        grid-template-columns: 150px auto;
        margin-bottom: 25px;
        align-items: start;

        > .field-label {
          margin-top: 15px;
        }

        > .field-input {
          width: 100%;
        }
      }
    }

    .media-inserter {
      cursor: pointer;

      &.vertical {
        > .one-image {
          margin-bottom: 25px;
        }
      }

      > .one-image {
        position: relative;

        > .media-wrap {
          > img {
            width: 100%;
          }

          > .remove-image-wrap {
            background-color: #ffffff;
            position: absolute;
            inset: 0;
            opacity: 0;
            transition: 0.2s;

            > .remove-image {
              position: absolute;
              top: 15px;
              right: 15px;

              > svg {
                height: 25px;
              }
            }
          }
        }

        :hover {
          .remove-image-wrap {
            opacity: 0.5;
          }
        }
      }

      .media-insert-block {
        background-color: #f5f5f5;
        width: 75%;
        aspect-ratio: 16/9;

        @media (max-width: 1500px) {
          width: 100%;
        }

        .upload-box {
          height: 100%;

          .image-container {
            height: 100%;
            position: relative;

            .before-image {
              position: absolute;
              top: 40%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 25px;
              color: black;
            }

            .media-inserter-text {
              position: absolute;
              bottom: 0;
              text-transform: uppercase;
              width: 100%;
              text-align: center;
              background-color: #e5e5e5;
              padding: 5px 0;

              @media (max-width: 1500px) {
                display: none;
              }
            }
          }
        }
      }

      .add-new-media-content {
        width: 50%;
        .add-new-media {
          display: inline-block;
          background-color: #009fff;
          color: white;
          vertical-align: middle;
          font-size: 17px;
          font-weight: 600;
          height: 50px;
          width: 160px;
          position: relative;
          cursor: pointer;
          margin-left: 5px;
          .text {
            width: 160px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }
        }

        .upload-box {
          margin-top: 30px;
          height: 150px;
          border: 2px dashed rgba(162, 162, 162, 0.5);
          position: relative;
          overflow: hidden;
          transition: height 0.5s;
          .text-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .first {
              text-align: center;
              font-size: 18px;
              font-weight: 600;
            }
            .second {
              text-align: center;
              font-size: 14px;
              color: #a2a2a2;
            }
          }
        }

        .upload-box.hidden {
          height: 0;
          border: 0;
        }
      }
    }

    > .add-part-btn-container {
      margin-top: 25px;

      > .add-part-btn {
        background-color: #222;
        opacity: 98%;
        color: #f0f0f0;
        font-size: 16px;
        padding: 5px 10px 5px 25px;
        border-radius: 20px;
        margin-left: 50%;
        transform: translateX(-50%);

        > .add-part-btn-icon {
          color: #fff;
          margin-left: 15px;
        }
      }
    }
  }

  .close-tour {
    position: absolute;
    right: 20px;
    top: 6px;
    font-size: 24px;
    cursor: pointer;
  }
  .w-content {
    height: calc(100% - 139px);
    overflow: auto;

    .new-content {
      padding: 43px 60px;
      h1 {
        font-size: 30px;
        font-weight: 600;
      }
      .fill-form {
        margin-top: 50px;
      }
      .start-time {
        // margin-top: 15px;
      }
    }

    .image-upload-block {
      height: 200px;
      max-height: 200px;
      cursor: pointer;
      .upload-box {
        height: 100%;
      }
      .image-container {
        margin: auto;
        border: 1px solid rgba(0, 0, 0, 0.06);
        height: 100%;
        position: relative;
        max-height: 400px;
        .before-image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 64px;
          color: $blue;
        }
        .pen {
          position: absolute;
          right: 15px;
          top: 15px;
          //color: $mid;
          opacity: 0.5;
        }
        img {
          width: 298px;
          height: 190px;
          object-fit: contain;
        }
      }
    }

    // .uploaded-images {
    //     display: flex;
    //     flex-wrap: wrap;
    //     .one-image {
    //         position: relative;
    //         height: 200px;
    //         width: calc(50% - 10px);
    //         // margin: 5px;
    //         margin-top: 0;
    //         img {
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         opacity: 1;
    //         transition: opacity 0.2s;
    //         pointer-events: none;
    //         }
    //         .remove-image {
    //         position: absolute;
    //         top: 3px;
    //         right: 10px;
    //         font-size: 20px;
    //         opacity: 0;
    //         cursor: pointer;
    //         transition: opacity 0.2s;
    //         }
    //         .move-image {
    //         position: absolute;
    //         top: 3px;
    //         right: 30px;
    //         font-size: 20px;
    //         opacity: 0;
    //         cursor: pointer;
    //         transition: opacity 0.2s;
    //         }
    //         // .text-wrap:has(.vfi-text-area.focus){
    //         //   opacity: 1;
    //         // }
    //         .text-wrap{
    //         position: absolute;
    //         left: 5%;
    //         right: 5%;
    //         height: 46%;
    //         transition: opacity 0.2s;
    //         .flag{
    //             object-fit: contain;
    //             display: inline-block;
    //             width: 30px;
    //             height: unset;
    //             opacity: 1;
    //         }
    //         .vfi-text-area{
    //             margin-top: 1%;
    //             background-color: white;
    //             height: 49px;
    //             font-size: 12px;
    //             .ck-editor{
    //             height: 100%;
    //             .ck-editor__main{
    //                 height: 100%;
    //                 .ck-content{
    //                 height: 100%;
    //                 p:first-child{margin-top: 5px;}
    //                 }
    //             }
    //             }
    //         }
    //         }
    //         .text-wrap.hidden{
    //         opacity: 0;
    //         }
    //         .text-en{
    //         top: 30px;
    //         }
    //         .text-fo{
    //         top: 115px;
    //         }
    //     }
    //     .one-image:hover {
    //         img {
    //         opacity: 0.6;
    //         }
    //         .remove-image {
    //         opacity: 1;
    //         }
    //         .move-image {
    //         opacity: 1;
    //         }
    //         .text-wrap{
    //         opacity: 1;
    //         }
    //     }
    // }

    .enable_editing {
      cursor: pointer;
      width: 100%;
      margin-left: 28%;
      color: orange;
      svg {
        margin-left: 5px;
        font-size: 12px;
      }
      p {
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }

    .enable_editing_noMargin {
      cursor: pointer;
      color: orange;
      width: 100%;
      font-weight: 400;
      margin-top: 5px;
      svg {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .enable_editing_checkbox {
      width: 100%;
      p {
        color: orange;
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 5px;
      }
    }

    .uploaded-images {
      .one-image {
        position: relative;
        margin-top: 0;
        margin-bottom: 40px;
        box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);
        // transition: ease-in-out 0.5s;
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.2s;
          // pointer-events: none;
        }
        .drag-element {
          position: absolute;
          height: 200px;
          width: 100%;
          left: 0;
          top: 0;
          cursor: move;
        }
        .remove-image {
          position: absolute;
          top: 3px;
          right: 25px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .move-image {
          position: absolute;
          top: 3px;
          right: 45px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .text-wrap {
          margin-top: 15px;
          .flag {
            object-fit: contain;
            display: inline-block;
            width: 30px;
            height: unset;
            opacity: 1;
            margin: 5px 0 2px;
            pointer-events: none;
          }
        }
        .text-en {
          top: 30px;
        }
        .text-fo {
          top: 115px;
        }
      }
      .one-image:hover {
        img {
          opacity: 0.6;
        }
        .remove-image {
          opacity: 1;
        }
        .move-image {
          opacity: 1;
        }
        .text-wrap {
          opacity: 1;
        }
      }
      .one-image.dragging {
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        // display: none;

        // max-width: 0px;
        opacity: 0;
        overflow: hidden;
        // padding: 0;
      }

      .one-image.dragging-on {
        background-color: $light;
      }
    }

    .new-content {
      img.flag {
        width: 30px;
      }
    }
    .new-content.one {
      .language-box {
        margin: auto;
        display: inline-block;
        padding-left: 0;
        padding-right: 100px;

        .l-include {
          margin-left: 20px;
        }
        .headline,
        .slug-url-wrap,
        .small-description,
        .description {
          margin-top: 50px;
        }
        .headline {
          margin-top: 30px;
        }
      }
    }

    .new-content.two {
      .duration-wrap,
      .activities-wrap,
      .difficulty-wrap,
      .minimum-age-wrap,
      .max-guests-wrap {
        margin-top: 20px;
        max-width: 500px;
        display: flex;
        align-items: center;
        p.text,
        p {
          width: 120px;
          margin: 0;
          // text-align: center;
        }
        .hours,
        .activities,
        .difficulty,
        .minimum-age,
        .max-guests {
          flex-grow: 1;
          flex-basis: 0;
        }
      }

      .add,
      .remove-one {
        cursor: pointer;
        user-select: none;
      }

      .add {
        margin-top: 10px;
        svg {
          margin-right: 5px;
        }
      }

      .languages-box,
      .highlights-box,
      .included-box,
      .whattobring-box {
        position: relative;
        margin: 10px 0;

        @media (max-width: 992px) {
          .language-box {
            padding-top: 0;
          }
          .english {
            padding-right: 15px;
          }
          .faroese {
            padding-left: 15px;
          }
        }

        @media (max-width: 767px) {
          .language-box {
            margin-bottom: 5px;
          }
        }

        .remove-one {
          position: absolute;
          top: 0;
          right: -15px;
        }
      }

      .flags {
        margin-top: 10px;
        .fo-flag {
          padding-left: 50px;
          @media (max-width: 992px) {
            padding-left: 15px;
          }
        }
        @media (max-width: 767px) {
          display: none;
        }
      }

      img.flag {
        margin-bottom: 5px;
      }
      .place-categories {
        margin-top: 60px;
        display: flex;
        height: 360px;
        flex-flow: wrap column;
        align-items: flex-end;
        width: 60%;
        .group {
          width: 30%;
        }
      }

      .duration-wrap {
        p {
          margin: auto;
        }

        .extra-filter {
          margin-right: 20px;
          white-space: nowrap;
          label {
            margin: 0;
          }
          .filter-text {
            display: inline-block;
          }
        }
        .many-lines {
          flex-wrap: wrap;
        }
      }

      // .css-yk16xz-control {
      //   min-height: 56px;
      // }
      // .css-1pahdxg-control {
      //   min-height: 56px;
      // }
    }

    .new-content.relive-gpx {
      .edit-contents {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        //width: 80%;
        .row {
          width: 100%;
          .upload-box {
            height: 100%;
            .image-container {
              margin: auto;
              border: 1px solid rgba(0, 0, 0, 0.06);
              width: 100%;
              position: relative;
              .before-image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 64px;
                color: $blue;
              }
              .gpx-label {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -30%);
                font-size: 12px;
                font-weight: 600;
                color: $blue;
              }
              .pen {
                position: absolute;
                right: 15px;
                top: 15px;
                //color: $mid;
                opacity: 0.5;
                padding: unset;
              }
              // img {
              //   width: 298px;
              //   height: 190px;
              //   object-fit: contain;
              // }
            }
          }
        }

        ul {
          padding-left: 0;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-evenly;
          justify-content: flex-start;
        }
        li {
          list-style-type: none;
          display: inline-block;
          margin-right: 10px;
          width: 350px;
        }

        .gpx-content {
          padding: 20px 0 0;
          //border: 1px solid rgba($color: #000000, $alpha: 0.3);
          //margin-bottom: 10px;
          display: flex;
          align-items: baseline;
          justify-content: space-around;
          .remove-button {
            cursor: pointer;
          }
        }
      }
      .gpx-topper {
        margin: 0 0 20px;
      }

      .gpx-wrap {
        padding-left: 0;
        padding-right: 0;
        .image-upload-block {
          aspect-ratio: unset;
          height: 200px;
        }
      }
      .relive-wrap {
        margin-bottom: 60px;
        padding-left: 0;
        padding-right: 0;
        .one-media {
          .image-container {
            height: 100%;
            max-height: unset;
          }
        }
      }
    }

    .new-content.four {
      .is-free-label {
        user-select: none;
        cursor: pointer;
        .is-free {
          margin-top: 0;
          display: flex;
          align-items: center;
          > span {
            margin-left: 10px;
          }
          > label {
            margin: 0;
            height: 20px;
          }
        }
      }
      .prices.hidden {
        display: none;
      }
    }

    .new-content.five {
      .add-new-media-content {
        width: 50%;
        .add-new-media {
          display: inline-block;
          background-color: #009fff;
          color: white;
          vertical-align: middle;
          font-size: 17px;
          font-weight: 600;
          height: 50px;
          width: 160px;
          position: relative;
          cursor: pointer;
          margin-left: 5px;
          .text {
            width: 160px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }
        }
        .upload-box {
          margin-top: 30px;
          height: 150px;
          border: 2px dashed rgba(162, 162, 162, 0.5);
          position: relative;
          overflow: hidden;
          transition: height 0.5s;
          .text-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .first {
              text-align: center;
              font-size: 18px;
              font-weight: 600;
            }
            .second {
              text-align: center;
              font-size: 14px;
              color: #a2a2a2;
            }
          }
        }

        .upload-box.hidden {
          height: 0;
          border: 0;
        }
      }

      .edit-contents {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        .image-upload-block {
          display: inline-block;
          cursor: pointer;
          .image-container {
            border: 1px solid $mid;
            width: 300px;
            height: 192px;
            position: relative;
            .before-image {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 64px;
              color: $blue;
            }
            .pen {
              position: absolute;
              right: 15px;
              top: 15px;
              //color: $mid;
              opacity: 0.5;
            }
            img {
              width: 298px;
              height: 190px;
              object-fit: contain;
            }
          }
        }
      }
    }
    .new-content.six {
      .search-box {
        // width: 50%;
        &.error {
          .vfi-input-text {
            border: 2px solid $red;
            border-radius: 2px;
          }
        }
      }
      // .edit-contents{
      //     p{
      //         margin: 15px 0 0 0;
      //     }
      // }

      .in-list,
      .not-in-list {
        transition: opacity 0.5s;
        opacity: 1;
        padding-bottom: 350px;
        p {
          margin-bottom: 5px;
          font-size: 15px;
        }
      }
      .place-select-bool {
        display: flex;
        > label {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          > label {
            height: 20px;
            margin: 0;
          }
        }
        span.text {
          margin: 0 5px;
        }
      }
      .hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
    .new-content.seven {
      .edit-contents {
        margin-top: 60px;
        .contact-content {
          margin-bottom: 15px;
          align-items: center;
          p {
            text-align: right;
            margin: 0;
          }
        }
      }
    }
    .new-content.eight {
      .new-edit {
        margin-top: 60px;
        .final-text {
          display: flex;
          //align-items: center;
          justify-content: space-around;
          width: 50%;
          margin: auto;
          .wooh {
            color: $blue;
            font-size: 75px;
            width: 20%;
          }
          p {
            font-size: 22px;
            font-weight: 600;
            color: $mid;
            width: 50%;
          }
          margin-bottom: 40px;
        }
        .post-button {
          background-color: $blue;
          width: min-content;
          margin: auto;
          padding: 10px 50px;
          color: white;
          font-weight: 600;
          font-size: 28px;
          cursor: pointer;
          user-select: none;
          transition: ease-in-out 0.2s;
        }
        .post-button:hover {
          background-color: $light-blue;
        }
      }
    }
  }
}
