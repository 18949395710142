@import "src/colorVariables.scss";

.with-tooltip,
.with-tooltip-2 {
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.with-tooltip-2 {
  margin-bottom: 16px;

  justify-content: flex-start;

  gap: 10px;

  &.no-margin {
    margin-bottom: 0;
  }
}

.tooltip-icon {
}

.tooltip-card {
  padding: 15px;
  max-width: 450px;

  width: 100%;

  font-size: 16px;

  text-align: justify;
}

.new-tour-operator {
  height: 100%;

  .close-tour-operator {
    position: absolute;
    right: 20px;
    top: 6px;
    font-size: 24px;
    cursor: pointer;
  }

  .w-content {
    height: calc(100% - 130px);
    overflow: auto;

    .new-content {
      .btn_current_submitted {
        width: 100%;
        margin-bottom: 10px;

        .btn {
          width: 115px;
          font-size: 12px;
          padding: 0;
          margin: 0;
          margin-right: 15px;
          background-color: $light;
          cursor: pointer;
          color: black;
        }

        .choosen {
          color: white;
          background-color: orange;
        }
      }

      padding: 43px 60px;

      h1 {
        font-size: 30px;
        font-weight: 600;
      }

      .fill-form {
        margin-top: 50px;
      }

      .start-time {
        margin-top: 15px;
      }
    }

    .image-upload-block {
      height: 200px;
      max-height: 200px;
      cursor: pointer;

      .upload-box {
        height: 100%;
      }

      .image-container {
        margin: auto;
        border: 1px solid rgba(0, 0, 0, 0.06);
        height: 100%;
        position: relative;
        max-height: 400px;

        .before-image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 64px;
          color: $blue;
        }

        .pen {
          position: absolute;
          right: 15px;
          top: 15px;
          //color: $mid;
          opacity: 0.5;
        }

        img {
          width: 298px;
          height: 190px;
          object-fit: contain;
        }
      }
    }

    .enable_editing {
      cursor: pointer;
      width: 100%;
      color: orange;

      svg {
        margin-left: 5px;
        font-size: 12px;
      }

      p {
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }

    .enable_editing_checkbox {
      width: 100%;

      p {
        color: orange;
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 5px;
      }
    }

    .uploaded-images {
      .one-image {
        position: relative;
        margin-top: 0;
        margin-bottom: 40px;
        box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);

        // transition: ease-in-out 0.5s;
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.2s;
          // pointer-events: none;
        }

        .drag-element {
          position: absolute;
          height: 200px;
          width: 100%;
          left: 0;
          top: 0;
          cursor: move;
        }

        .remove-image {
          position: absolute;
          top: 3px;
          right: 25px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }

        .move-image {
          position: absolute;
          top: 3px;
          right: 45px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }

        .text-wrap {
          margin-top: 15px;

          .flag {
            object-fit: contain;
            display: inline-block;
            width: 30px;
            height: unset;
            opacity: 1;
            margin: 5px 0 2px;
            pointer-events: none;
          }
        }

        .text-en {
          top: 30px;
        }

        .text-fo {
          top: 115px;
        }
      }

      .one-image:hover {
        img {
          opacity: 0.6;
        }

        .remove-image {
          opacity: 1;
        }

        .move-image {
          opacity: 1;
        }

        .text-wrap {
          opacity: 1;
        }
      }

      .one-image.dragging {
        opacity: 0;
        overflow: hidden;
      }

      .one-image.dragging-on {
        background-color: $light;
      }
    }

    // .new-content.one {
    //   .language-box {
    //     margin: auto;
    //     display: inline-block;
    //     padding-left: 0;
    //     padding-right: 100px;

    //     img.flag {
    //       width: 30px;
    //     }

    //     .l-include {
    //       margin-left: 20px;
    //     }

    //     .headline,
    //     .slug-url-wrap,
    //     .small-description,
    //     .description {
    //       margin-top: 50px;
    //     }

    //     .headline {
    //       margin-top: 30px;
    //     }
    //   }
    // }

    .new-content.five {
      .sub-header {
        width: 60%;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 17px;
        // margin-bottom: 50px;
        margin-bottom: 0.625rem;
      }

      .enable_editing {
        margin: 0;
      }

      .instagram-section {
        .instagram-header {
          margin-top: 40px;
          text-transform: uppercase;
          opacity: 0.98;
          color: #a2a2a2;
          font-size: 12px;
          font-weight: 600;
        }

        .instagram-description {
          // width: 60%;
          color: #313131;
          line-height: 17px;
        }

        // .instagram-in-whatson {
        //   .row {
        //     margin-top: 35px;
        //     width: 60%;
        //     display: flex;
        //     flex-direction: column;

        //     .hashtag,
        //     .profile-link {
        //       display: flex;
        //       gap: 35px;
        //       flex-direction: row;
        //       max-width: unset;
        //       width: 100%;
        //       padding: 0;
        //       align-items: center;

        //       p {
        //         width: 150px;
        //         margin: 0;
        //       }

        //       .vfi-input-text {
        //         flex-grow: 1;
        //       }
        //     }
        //   }

        //   .image-count-text {
        //     width: 60%;
        //     margin-top: 25px;
        //   }

        //   .vfi-custom-select-wrap {
        //     width: 60%;
        //     margin-bottom: 25px;
        //   }
        // }
      }

      .one-image {
        &:hover {
          img {
            opacity: 1;
          }
        }

        .media-wrap {
          position: relative;

          .image-desc {
            position: absolute;
            top: 5px;
            left: 5px;
            background-color: white;
            display: inline-block;
            font-family: Roboto;
            color: #444444;
            font-size: 10px;
            text-align: center;
            padding: 5px 10px;
            border-radius: 2px;
          }

          .edit-btn {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: white;
            display: inline-block;
            color: #444444;
            font-size: 10px;
            text-align: center;
            padding: 5px 10px;
            border-radius: 2px;
            cursor: pointer;
          }

          .img-cp {
            display: inline-block;
            position: absolute;
            top: 35px;
            right: 5px;
            background-color: white;
            border-radius: 3px;

            div {
              padding: 15px 15px;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 15px;
              height: 19px;
              font-size: 15px;
              margin-top: 7px;
              cursor: pointer;

              &.delete-img {
                margin-top: 15px;
                margin-bottom: 7px;
                color: #f64e60;
              }
            }
          }
        }
      }

      .add-new-media-content {
        width: 50%;

        .add-new-media {
          display: inline-block;
          background-color: #009fff;
          color: white;
          vertical-align: middle;
          font-size: 17px;
          font-weight: 600;
          height: 50px;
          width: 160px;
          position: relative;
          cursor: pointer;
          margin-left: 5px;

          .text {
            width: 160px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }
        }

        .upload-box {
          margin-top: 30px;
          height: 150px;
          border: 2px dashed rgba(162, 162, 162, 0.5);
          position: relative;
          overflow: hidden;
          transition: height 0.5s;

          .text-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .first {
              text-align: center;
              font-size: 18px;
              font-weight: 600;
            }

            .second {
              text-align: center;
              font-size: 14px;
              color: #a2a2a2;
            }
          }
        }

        .upload-box.hidden {
          height: 0;
          border: 0;
        }
      }

      .edit-contents {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        .image-upload-block {
          display: inline-block;
          cursor: pointer;

          .image-container {
            border: 1px solid $mid;
            width: 300px;
            height: 192px;
            position: relative;

            .before-image {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 64px;
              color: $blue;
            }

            .pen {
              position: absolute;
              right: 15px;
              top: 15px;
              //color: $mid;
              opacity: 0.5;
            }

            img {
              width: 298px;
              height: 190px;
              object-fit: contain;
            }
          }
        }
      }

      .optional-content {
        width: 60%;

        .header {
          text-transform: uppercase;
          color: #000000;
          font-size: 15px;
          margin-bottom: 25px;
        }

        .sub-header {
          text-transform: uppercase;
          color: #a2a2a2;
          font-size: 12px;
          font-weight: 600;
        }

        .opt-media-input {
          margin-bottom: 15px;

          .delete-btn {
            margin-right: -50px;
            font-size: 18px;
            overflow: auto;
            cursor: pointer;

            .icon {
              float: right;
              clear: both;
            }
          }
        }

        .add-more-btn {
          cursor: pointer;
          text-align: center;
          text-decoration: underline;

          .plus-icon {
            margin-left: 15px;
          }
        }
      }

      .img-error-cont {
        color: red;
        position: absolute;
        bottom: 10px;
        text-align: center;
        width: 100%;
      }
    }

    .new-content.six {
      .enable_editing {
        margin: 0;
      }

      .search-box {
        width: 100%;

        &.error {
          .vfi-input-text {
            border: 2px solid $red;
            border-radius: 2px;
          }
        }
      }

      .edit-contents {
        p {
          margin: 15px 0 0 0;
        }

        // .more-values {
        //   padding-bottom: 30px;
        //   margin-top: 20px;
        //   width: 50%;

        //   p {
        //     text-transform: uppercase;
        //   }
        // }
      }

      .contact-information {
        margin-top: 50px;
        padding-bottom: 350px;

        .contact-header {
          text-transform: uppercase;
        }

        .field-row {
          width: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 20px;

          label {
            flex-basis: 170px;
          }

          > div {
            width: 100%;
            flex-basis: auto;

            &.phone-input {
              display: flex;
              flex-direction: row;
              gap: 10px;

              .country-code {
                min-width: 100px;
              }

              .phone-number {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .new-content.team-section {
      .add-member-btn {
        margin-top: 55px;
      }

      .team-members-table {
        margin-top: 45px;
        width: 80%;

        tr {
          &:hover {
            background-color: #f4f4f4;
          }

          height: 50px;

          th {
            color: #a2a2a2;
            font-size: 14px;
            font-weight: normal;
            border-bottom: 1px rgba(162, 162, 162, 0.1) solid;

            .sort {
              margin-left: 9px;
              cursor: pointer;
            }
          }

          td {
            color: #313131;
            font-size: 16px;
            border-bottom: 1px rgba(162, 162, 162, 0.1) solid;
            position: relative;

            .profile-img {
              object-fit: cover;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin: 0 15px;
              color: #ddd;
              vertical-align: middle;
            }

            .member-edit-btn {
              cursor: pointer;
              float: right;
              // margin-top: 7px;
              padding: 0 15px;

              img {
                color: #373737;
              }
            }

            .member-cp {
              display: inline-block;
              position: absolute;
              top: 45px;
              right: 5px;
              background-color: #f7f8f9;
              border-radius: 3px;
              z-index: 2;

              div {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;
                height: 19px;
                font-size: 15px;
                padding: 25px 50px 25px 15px;
                cursor: pointer;

                &:hover {
                  background-color: #f4f4f4;
                }

                &.delete-member {
                  color: #f64e60;
                }
              }

              hr {
                margin: 0;
              }
            }
          }
        }
      }

      .wrapper {
        .user-panel {
          .top-header {
            height: 250px;

            .Profile {
              text-transform: uppercase;
            }
          }

          .body {
            height: calc(100% - 250px);
            overflow: auto;
          }

          .profile-img {
            object-fit: cover;
            height: 110px;
            width: 110px;
            border-radius: 50%;
            color: #ddd;
          }

          .form-section-header {
            width: 100%;
            margin-top: 25px;
            color: #a2a2a2;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            padding-top: 20px;
          }

          .text {
            &.short-bio {
              vertical-align: top;
            }

            &.change-upload {
              vertical-align: top;
              margin-top: 60px;
            }
          }

          .input {
            height: unset;

            .phone-input {
              display: flex;
              flex-direction: row;
              gap: 10px;

              .country-code {
                min-width: 100px;

                div {
                  padding-top: 0;
                  padding-bottom: 0;
                  margin-top: 0;
                  margin-bottom: 0;
                }
              }

              .phone-number {
                width: 100%;
              }
            }
          }

          .update-btn {
            display: inline-block;
            margin-left: 45px;
            vertical-align: top;
            margin-top: 40px;
          }

          .add-btn {
            margin-top: 50px;
          }
        }
      }
    }

    .new-content.eight {
      height: 100%;
      position: relative;
      width: 60%;
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;

      .ready-cont {
        display: relative;
        width: 500px;
        margin: 0 auto;

        .register-sub-header {
          font-size: 18px;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 350px;
        }

        .whatson-navigation {
          width: 100%;
          position: relative;
          right: unset;
          top: unset;
          display: flex;
          flex-direction: row;
          justify-content: end;

          .controls {
            display: flex;
            flex-direction: row;
          }
        }
      }

      .registration-complete-cont {
        .register-sub-header {
          width: 60%;
          display: block;
          margin: 0 auto;
        }
        .complete-buttons {
          margin-top: 50px;
          display: flex;
          justify-content: center;
          width: 60%;
          margin: 50px auto;
          gap: 35px;
        }
      }

      .new-edit {
        margin-top: 60px;

        .final-text {
          display: flex;
          //align-items: center;
          justify-content: space-around;
          width: 50%;
          margin: auto;

          .wooh {
            color: $blue;
            font-size: 75px;
            width: 20%;
          }

          p {
            font-size: 22px;
            font-weight: 600;
            color: $mid;
            width: 50%;
          }

          margin-bottom: 40px;
        }

        .post-button {
          background-color: $blue;
          width: min-content;
          margin: auto;
          padding: 10px 50px;
          color: white;
          font-weight: 600;
          font-size: 28px;
          cursor: pointer;
          user-select: none;
          transition: ease-in-out 0.2s;
        }

        .post-button:hover {
          background-color: $light-blue;
        }
      }
    }
  }
}
