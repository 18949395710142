@use "../../../breakpointVariables" as breakpoints;

.datePickerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-family: "Source Sans Pro", sans-serif;

  font-size: 16px;
  font-weight: 400;
  color: #313131;

  margin: 0;
}

.input {
  display: flex;
  height: 50px;
  padding: var(--spacing-spacing-xs, 15px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;

  border: unset;
  border-radius: 2px;
  background-color: #f5f5f5;

  max-width: 173px;
  margin: 0 38px;

  @media screen and (breakpoints.$sendistovanTablet) {
    margin: 0;
  }

  &:focus {
    outline: 2px solid #009fff;
  }

  &.error {
    outline: 2px solid red;
  }
}

