@use "../../../../breakpointVariables" as breakpoints;

// max width of 1404px - 20px padding - 20px padding
$horizontal-padding: 20px;

.stepNavigation {
  z-index: 1;
  position: relative;

  @media screen and (breakpoints.$sendistovanTabletReverse) {
    background-color: #fff;

    position: sticky;
    top: 0;
    left: 0;
  }

  padding: 0 $horizontal-padding;

  @media screen and (breakpoints.$sendistovanTablet) {
    position: unset;
    padding: 0;
  }

  .close_submit {
    z-index: 1;

    background-color: rgba(0, 0, 0, 0);

    position: absolute;
    top: 8px;
    right: 16px;
  }
}

.stepNavigationButtonContainer {
  display: flex;
  align-items: center;
  gap: 120px;

  padding: 30px 0;

  border-bottom: rgba(162, 162, 162, 0.1) 1px solid;

  overflow-x: auto;

  @media screen and (breakpoints.$sendistovanTablet) {
    gap: 40px;
    padding: $horizontal-padding 20px;
  }

  @media screen and (breakpoints.$sendistovanMobile) {
    padding: $horizontal-padding 30px;
  }

  @media screen and (breakpoints.$sendistovanTabletReverse) {
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b3b3b3c5;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(126, 126, 126, 0.525);
    }
  }
}

.stepButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  padding: 0;
  border: none;

  .stepIcon {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 58px;
    height: 58px;
    border-radius: 50%;
    margin-bottom: 2px;

    // background-color: rgba(162, 162, 162, 0.2);
    background-color: #f4f5f7;

    color: #333;

    font-size: 24px;

    path {
      stroke-width: 5px;
      stroke: #333;

      transition: 0.1s;
    }

    transition: 0.1s;

    @media screen and (breakpoints.$sendistovanTablet) {
      width: 54px;
      height: 54px;
    }
  }

  .stepTitle {
    font-size: 16px;
    line-height: 17px;

    font-weight: 500;

    color: #37373798;

    transition: 0.1s;

    @media screen and (breakpoints.$sendistovanTablet) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .active {
    background-color: #000000;
    color: #ffffff;

    path {
      stroke-width: 5px;
      stroke: #fff;
    }
  }

  .activeText {
    color: #333;
  }

  .errorIndicator {
    position: absolute;
    top: 0;
    right: 0;

    border-radius: 50%;
    background-color: red;

    width: 14px;
    height: 14px;

    font-size: 10px;
    color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.disabled {
    opacity: 0.5;

    pointer-events: none;
    user-select: none;
  }
}

.StepNavigationControls {
  position: absolute;
  bottom: -88px;
  right: 20px;

  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 20px;

  .StepNavigationControlsButton {

    padding: 0 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 90px;
    width: fit-content;
    height: 50px;

    border-radius: 4px;

    font-size: 13px;

    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.05);

    text-transform: uppercase;

    letter-spacing: 2px;

    font-weight: 700;
    line-height: 21px;

    text-align: center;

    &.StepNavigationControlsButtonNext {
      background-color: #009fff;
      color: #ffffff;
    }

    &.StepNavigationControlsButtonBack {
      // background-color: rgba(162, 162, 162, 0.1);
      background-color: #f6f6f6;
      color: #000000;
    }

    &.StepNavigationControlsButtonDisabled {
      background-color: #f4f5f7;
      color: #acacac;
    }

    @media screen and (breakpoints.$sendistovanTablet) {
      flex: 0 0 calc(50% - 10px);
      margin-left: auto;
    }
  }

  @media screen and (breakpoints.$sendistovanTablet) {
    top: unset;
    right: unset;

    bottom: 0;
    right: unset;

    width: 100%;

    padding: 20px;

    background-color: #ffffff;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.11);
  }
}
