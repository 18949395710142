@import "src/colorVariables.scss";
.whatson-create-new {
  // Start making things global..

  height: 100%;

  .upload-media-wrapper {
    max-width: 1240px;
    margin-top: 60px;
    transition: 0.1s opacity;

    &.disabled {
      opacity: 0.8;

      pointer-events: none;
    }
  }

  .w-content {
    overflow: auto;
    flex-grow: 1;
    position: relative;

    .image-upload-block {
      height: unset;
      max-height: unset;
      aspect-ratio: 16 / 9;
      cursor: pointer;
      background-color: $light;
      .upload-box {
        height: 100%;
      }
      .image-container {
        margin: auto;
        border: 1px solid transparent;
        height: 100%;
        position: relative;
        max-height: 400px;
        .before-image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 42px;
          color: black;
        }
        .pen {
          position: absolute;
          right: 10px;
          top: 10px;
          opacity: 1;
        }
        img {
          width: 298px;
          height: 190px;
          object-fit: contain;
        }
      }
    }

    .uploaded-images {
      .one-image {
        position: relative;
        margin-top: 0;
        margin-bottom: 40px;
        box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);
        .media-wrap {
          overflow: hidden;
          width: 100%;
          aspect-ratio: 16 / 9;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.2s;
          }
        }
        .drag-element {
          position: absolute;
          height: 210px;
          width: 100%;
          left: 0;
          top: 0;
          cursor: move;
        }
        .remove-image {
          position: absolute;
          top: 3px;
          right: 25px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .move-image {
          position: absolute;
          top: 3px;
          right: 45px;
          font-size: 20px;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.2s;
        }
        .text-wrap {
          margin-top: 15px;
          .flag {
            object-fit: contain;
            display: inline-block;
            width: 30px;
            height: unset;
            opacity: 1;
            margin: 5px 0 2px;
            pointer-events: none;
          }
          textarea {
            height: 83px;
          }
          .errors {
            visibility: hidden;
          }
        }
        .text-en {
          top: 30px;
        }
        .text-fo {
          top: 115px;
        }
      }
      .one-image:hover {
        img {
          opacity: 0.6;
        }
        .remove-image {
          opacity: 1;
        }
        .move-image {
          opacity: 1;
        }
        .text-wrap {
          opacity: 1;
        }
      }
      .one-image.dragging {
        opacity: 0;
        overflow: hidden;
      }

      .one-image.dragging-on {
        background-color: $light;
      }
    }

    .english,
    .faroese {
      &:not(.active) {
        .difficulty-wrap,
        .children-d-wrap,
        .route-surface-wrap,
        .special-comments-wrap,
        .language-box {
          opacity: 0.1;
        }
      }
    }

    .new-content.one,
    .new-content.two,
    .new-content.five,
    .new-content.six,
    .new-content.seven {
      .instagram-enabled {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        margin: 10px 0 20px;
        width: fit-content;
        label {
          margin-bottom: 0;
          height: 20px;
        }
        > label {
          margin-right: 10px;
        }
      }
      .language-box {
        .headline,
        .small-description,
        .description,
        .summary {
          .text.col-xl-3 {
            margin-top: 0;
          }
        }
        .small-description {
          .vfi-text-area {
            textarea {
              height: 130px;
            }
          }
        }
        .description {
          .vfi-text-area {
            textarea {
              height: 260px;
            }
          }
        }
      }

      .language-box.faroese:not(.active),
      .language-box.english:not(.active) {
        .headline,
        .small-description,
        .description,
        .summary,
        .map-d,
        .text,
        .map-summary,
        .card-summary,
        p,
        .headline-startingpoint,
        .description-startingpoint,
        .headline-gettingthere,
        .description-gettingthere,
        .good-to-know {
          opacity: 0.1;
          pointer-events: none;
        }
      }

      .language-box {
        img.flag {
          width: 30px;
        }
        .l-include {
          margin-left: 20px;
          margin-bottom: 0;
          height: 20px;
          vertical-align: middle;
        }
        .headline,
        .small-description,
        .description {
          margin-top: 50px;
        }
        .headline {
          margin-top: 30px;
        }
        &.english {
          @media (max-width: 992px) {
            border: none;
            padding-right: 15px;
          }
        }
        &.faroese {
          @media (max-width: 992px) {
            padding-left: 15px;
            padding-top: 0px;
          }
        }
      }
    }

    .enable_editing {
      cursor: pointer;
      width: 100%;
      margin-left: 28%;
      color: orange;
      &.enabled {
        color: $green;
      }
      svg {
        margin-left: 5px;
        font-size: 12px;
      }
      p {
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }

    .enable_editing_checkbox {
      width: 100%;
      position: relative;
      user-select: none;
      p {
        color: orange;
        display: inline-block;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-left: 5px;
        position: absolute;
        transform: translateY(-2px);
      }
      &.enabled {
        p {
          color: $green;
        }
      }
      label {
        cursor: pointer;
      }
    }

    .enable_editing_noMargin {
      cursor: pointer;
      color: orange;
      width: 100%;
      font-weight: 400;
      margin-top: 5px;
      &.enabled {
        color: $green;
      }
      svg {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .text-include {
      margin-right: 5px;
    }

    .new-content {
      padding: 43px 60px 140px;
      @media ($smallerDesktop) {
        padding: 60px 20px;
        .whatson-navigation {
          right: 20px;
          padding-top: 0;
        }
      }
      h1 {
        font-size: 30px;
        font-weight: 700;
      }

      h2 {
        height: 23px;
        font-size: 18px;
        margin-bottom: 0;
      }

      .btn_current_submitted {
        width: 100%;
        margin-bottom: 10px;
        .btn {
          width: 115px;
          font-size: 12px;
          padding: 0;
          margin: 0;
          margin-right: 15px;
          background-color: $light;
          cursor: pointer;
          color: black;
        }
        .choosen {
          color: white;
          background-color: orange;
        }
      }
      .fill-form {
        width: 100%;

        display: flex;
        margin-top: 50px;

        .language-box {
          padding-left: 0;
          flex: 0 1 550px;
          margin-bottom: 80px;
          img.flag {
            width: 30px;
          }
          .l-include {
            margin-left: 20px;
          }
          .text {
            flex: 0 0 138px;
          }
          .headline,
          .slug-url-wrap,
          .small-description,
          .description,
          .map-summary,
          .card-summary {
            margin-top: 50px;
            display: flex;
            .text {
              padding-top: 15px;
              justify-content: flex-start;
              .tootlip-icon {
                padding-left: 0.313rem;
              }
            }
          }
          .headline {
            margin-top: 30px;
          }
          .small-description {
            .ck-editor {
              .ck-content {
                height: 130px;
              }
            }
          }
          .description {
            .ck-editor {
              .ck-content {
                height: 260px;
              }
            }
          }

          .vfi-input-text,
          .vfi-text-area {
            flex: 0 1 415px;
          }
        }

        .space-between {
          flex: 0 1 120px;
          .space-between-line {
            width: 1px;
            height: 100%;
            margin: auto;
            background-color: $light;
          }
        }

        @media ($tablet) {
          display: block;
        }
      }

      .restrictions {
        margin-top: 50px;
        max-width: 330px;
        .min-age,
        .capacity {
          display: flex;
        }
        .text {
          flex: 0 0 140px;
        }
        .vfi-input-text {
          flex-shrink: 1;
        }
      }
    }

    .new-content.two {
      .event-categories,
      .place-categories {
        list-style: none;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        white-space: nowrap;
        margin: 60px auto 20px 0;
        padding: 0;
        width: 100%;
        max-width: 750px;
        .group {
          width: 30%;
          margin-bottom: 6px;
          font-size: 15px;
          label {
            user-select: none;
            cursor: pointer;
            margin-bottom: 0;
            position: relative;
            .vfi-checkbox-2 {
              margin-right: 12px;
            }
            .text {
              display: inline-block;
              position: absolute;
              bottom: 5px;
            }
          }
        }

        @media ($tablet) {
          columns: 1;
        }
      }
      .add-category {
        display: flex;
        align-items: center;
        .label {
          margin-right: 20px;
        }
        .add-button {
          color: $green;
          font-size: 24px;
          cursor: pointer;
        }
      }

      .verify-errors {
        margin-bottom: 40px;
        span {
          color: $red;
        }
      }

      .know-en-wrap,
      .know-fo-wrap {
        margin-bottom: 10px;
      }
    }

    .new-content.three {
      .top-title {
        margin-top: 40px;
        margin-bottom: 10px;
      }
      .dates {
        margin: auto;
      }
    }

    .new-content.four {
      .is-free {
        margin-top: 40px;
        label {
          cursor: pointer;
        }
        position: relative;
        .text {
          position: absolute;
          left: 30px;
        }
      }
    }

    .permission-section {
      .capacity {
        margin-top: 10px;
      }
    }

    .new-content.five {
      .edit-contents {
        margin-top: 30px;

        .text-wrap {
          margin-top: 10px;
        }

        .add-new-media-content {
          width: 50%;
          .add-new-media {
            display: inline-block;
            background-color: #009fff;
            color: white;
            vertical-align: middle;
            font-size: 17px;
            font-weight: 600;
            height: 50px;
            width: 160px;
            position: relative;
            cursor: pointer;
            margin-left: 5px;
            .text {
              width: 160px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
            }
          }
          .upload-box {
            margin-top: 30px;
            height: 150px;
            border: 2px dashed rgba(162, 162, 162, 0.5);
            position: relative;
            overflow: hidden;
            transition: height 0.5s;
            .text-content {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              .first {
                text-align: center;
                font-size: 18px;
                font-weight: 600;
              }
              .second {
                text-align: center;
                font-size: 14px;
                color: #a2a2a2;
              }
            }
          }

          .upload-box.hidden {
            height: 0;
            border: 0;
          }
        }
      }

      .instagram-headline {
        margin-top: 40px;
        margin-bottom: 0;
        color: $mid;
        font-weight: 600;
        font-size: 12px;
      }
    }

    .new-content.six,
    .place-section {
      display: flex;
      flex-direction: column;

      .place-select-bool {
        margin-top: 20px;
      }
      .more-values {
        margin-top: 40px;
        padding-bottom: 20px;
        display: flex;
        gap: 40px;
        margin-top: 48px;
        .zip-code,
        .region {
          flex: 0 1 325px;
        }
        p {
          margin-top: 0;
          margin-bottom: 12px;
          font-size: 15px;
          text-transform: uppercase;
        }
        .location-select__control {
          height: 50px;
        }
      }

      .max-capacity {
        opacity: 1;
        transition: ease-in-out 0.2s;
        max-width: 675px;
        padding-bottom: 200px;
        p {
          margin-top: 20px;
          margin-bottom: 5px;
          font-size: 15px;
        }
        &.hidden {
          pointer-events: none;
          opacity: 0;
        }
      }

      .hidden {
        opacity: 0;
        pointer-events: none;
        transform: scaleY(0);
        height: 0;
        display: none;
      }

      .on-list-determine-parent {
        margin-top: 20px;
        display: flex;
      }

      .on-list-determine {
        display: flex;
        width: max-content;
        .not-in-list {
          font-size: 15px;
          margin: 0 8px 0 6px;
        }
      }

      .question-info {
        display: inline-block;
      }

      .choose-place-text {
        font-size: 15px;
        margin-bottom: 10px;
      }

      .map-inclusion > label {
        display: flex;
        margin-top: 10px;
        .include-map {
          margin-bottom: 0;
        }
        p {
          margin-top: 0;
          margin-left: 10px;
        }
      }
    }

    .new-content.seven {
      .who-is-host {
        label {
          display: flex;
          align-items: center;
          margin: 0;
        }
        .text {
          margin-left: 10px;
          font-size: 15px;
        }
      }
      .edit-contents {
        opacity: 1;
        transition: opacity 0.5s;
        .search-box {
          ol {
            margin-bottom: 0.625rem;
          }
        }
        .contact-text {
          font-size: 15px;
        }
        .contact-content {
          display: flex;
          align-items: center;
          margin-top: 30px;
          max-width: 670px;

          .contact-content-inputs {
            display: flex;
            flex-grow: 1;
            gap: 10px;
            > div {
              flex-grow: 1;
            }
            .country-code {
              flex: 0 0 80px;
              width: 80px;
              .vfi-custom-select {
                height: 100%;
                .country-code__control {
                  height: 100%;
                }
              }
            }
          }

          p.contact-sub-text {
            margin: 0;
            width: 170px;
            font-size: 15px;
            text-align: left;
          }
          .input {
            display: flex;
            align-items: center;
          }
          .vfi-custom-select-wrap,
          .vfi-input-text {
          }
          .input-40 {
            width: 41%;
          }
          .input-60 {
            width: 59%;
            padding-left: 10px;
          }
        }
      }

      .edit-contents.hidden {
        user-select: none;
        opacity: 0;
        pointer-events: none;
      }
    }

    .finnished {
      .new-edit {
        margin-top: 60px;
        .final-text {
          display: flex;
          justify-content: space-around;
          width: 60%;
          margin: auto;
          .wooh {
            color: $blue;
            font-size: 75px;
            width: 20%;
          }
          p {
            font-size: 22px;
            font-weight: 600;
            color: $mid;
            width: 50%;
          }
          margin-bottom: 40px;
        }
      }
    }

    .new-content.eight,
    .nine {
      .new-edit {
        margin-top: 60px;
        .final-text {
          display: flex;
          justify-content: space-around;
          width: 60%;
          margin: auto;
          .wooh {
            color: $blue;
            font-size: 75px;
            width: 20%;
          }
          p {
            font-size: 22px;
            font-weight: 600;
            color: $mid;
            width: 50%;
          }
          margin-bottom: 40px;
        }
        .post-button {
          background-color: $blue;
          width: min-content;
          margin: auto;
          padding: 10px 50px;
          color: white;
          font-weight: 600;
          font-size: 28px;
          cursor: pointer;
          user-select: none;
          transition: ease-in-out 0.2s;
        }
        .post-button:hover {
          background-color: $light-blue;
        }
      }
      h3 {
        margin-top: 60px;
        text-align: center;
      }
      p {
        text-align: center;
      }
      .goose-image {
        margin-top: 70px;
        display: flex;
        justify-content: center;
        height: 400px;
      }
    }
  }
}
